import axios from 'axios'

const api = axios.create({
	baseURL: 'https://api.ozreservations.com/api/v1',
})

export default {
	searchLocations(query: string) {
		return api.get(`/locations/search?city=${encodeURIComponent(query)}`)
	},
	searchAccommodation(params: string) {
		return api.get('/accommodation/reservation/search', { params })
	},
}
