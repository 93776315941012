<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div class="bg-white rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
            <div class="p-6 space-y-4">
                <h2 class="text-xl font-semibold">New Seasonal Rate</h2>

                <div v-if="error" class="bg-red-100 text-red-700 border border-red-200 p-2">
                    {{ error }}
                </div>

                <form @submit.prevent="createSeasonal" class="space-y-3">
                    <!-- Type -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Listing Type</label>
                        <input type="text" v-model="type"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                            placeholder="e.g. family_room" />
                    </div>

                    <!-- Season Name -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Season Name</label>
                        <input type="text" v-model="season"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                            placeholder="e.g. Christmas, Easter..." />
                    </div>

                    <!-- Start/End Dates -->
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Start Date</label>
                            <input type="date" v-model="startDate"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">End Date</label>
                            <input type="date" v-model="endDate"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                    </div>

                    <!-- Rate + percentage? -->
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Rate (must use whole numbers for percentages)</label>
                            <input type="number" step="1" v-model="rate"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full"
                                placeholder="10 for e.g. 10% or $10" />
                        </div>
                        <div class="flex items-center gap-2 mt-5">
                            <input id="percentage" type="checkbox" v-model="isPercentage"
                                class="h-4 w-4 text-blue-600" />
                            <label for="percentage" class="text-sm text-gray-700">Percentage?</label>
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="flex justify-end space-x-3 pt-2">
                        <button type="button" @click="$emit('close')"
                            class="px-4 py-2 border border-gray-300 rounded text-gray-700">
                            Cancel
                        </button>
                        <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { api } from '@/lib/api'
import { formatDateForAPI } from '@/lib/dateutils'

interface Props {
    propertyId: number | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['close', 'created'])

const error = ref('')
const type = ref('')
const season = ref('')
const startDate = ref('')
const endDate = ref('')
const rate = ref<number | null>(null)
const isPercentage = ref<boolean>(true)

async function createSeasonal() {
    if (!props.propertyId) {
        error.value = 'No property selected.'
        return
    }
    error.value = ''

    try {
        // Example: POST /rates/seasonal
        const payload = {
            property_id: props.propertyId,
            type: type.value,
            start_date: startDate.value,
            end_date: endDate.value,
            season_name: season.value,
            is_percentage: isPercentage.value,
            adjustment_amount: rate.value
        }
        await api.post('/rates/seasonal', payload)
        emit('created')
    } catch (err: any) {
        console.error('Failed to create seasonal rate:', err)
        error.value = err?.response?.data?.message || 'Failed to create seasonal rate.'
    }
}
</script>
