// src/lib/api.ts
import axios from 'axios';
import router from '../router';

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

console.log('Current environment:', import.meta.env.VITE_ENV);
console.log('API URL:', import.meta.env.VITE_API_URL);

// Create a flag to prevent multiple refresh requests
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Subscribe to token refresh
const subscribeTokenRefresh = (cb: (token: string) => void) => {
    refreshSubscribers.push(cb);
};

// Notify all subscribers about new token
const onTokenRefreshed = (token: string) => {
    refreshSubscribers.map(cb => cb(token));
    refreshSubscribers = [];
};

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status !== 401 || originalRequest._retry) {
            return Promise.reject(error);
        }

        if (isRefreshing) {
            return new Promise((resolve) => {
                subscribeTokenRefresh((token) => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(axios(originalRequest));
                });
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
            const response = await axios.post(
                `${import.meta.env.VITE_API_URL}/refresh`,
                {
                    refresh_token: localStorage.getItem('refresh_token')
                }
            );

            const { access_token, refresh_token } = response.data;
            localStorage.setItem('token', access_token);
            localStorage.setItem('refresh_token', refresh_token);

            onTokenRefreshed(access_token);
            isRefreshing = false;

            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            return axios(originalRequest);
        } catch (err) {
            isRefreshing = false;
            localStorage.clear();
            router.push('/login');
            return Promise.reject(err);
        }
    }
);

export { api };
