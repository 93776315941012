<!-- src/views/ContactView.vue -->
<script setup>
import { ref } from 'vue'
import { api } from '@/lib/api'
import Footer from '@/components/Footer.vue'
import logo from '@/assets/ozreservations.png'

// Define reactive form data
const formData = ref({
  name: '',
  email: '',
  message: ''
})

// Reactive messages to show feedback
const successMessage = ref('')
const errorMessage = ref('')

// Submit handler for the contact form
const submitForm = async () => {
  try {
    // Post the form data to the contact API endpoint
    await api.post('/contact', { name: formData.value.name, email: formData.value.email, message: formData.value.message })
    successMessage.value = 'Your message has been sent!'
    errorMessage.value = ''
    // Optionally, reset the form
    formData.value = { name: '', email: '', message: '' }
  } catch (err) {
    errorMessage.value = 'There was an error sending your message. Please try again later.'
    successMessage.value = ''
  }
}
</script>

<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
    <!-- Logo Section -->
    <div class="sm:mx-auto sm:w-full sm:max-w-md lg:max-w-lg">
      <img class="mx-auto h-48 w-auto" :src="logo" alt="OzReservations" />
    </div>

    <!-- Card Section -->
    <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md md:max-w-xl lg:max-w-2xl">
      <div class="bg-white py-10 px-6 shadow sm:rounded-lg sm:px-10">
        <h1 class="text-3xl font-bold text-center mb-6">Contact Us</h1>
        <p class="pt-2 text-gray-700">
          Donna is the Managing Director and CEO: donna@ozreservations.com.
        </p>
        <p class="pt-2 text-gray-700">
          Otherwise, for technical issues, you can send a message to the CTO james@ozreservations.com.
        </p>
        <p class="pt-2 text-gray-700">
          If you prefer to fill out a form and have it arrive without getting into your emails,
          please use the contact form below. We look forward to hearing from you!
        </p>
        
        <!-- Contact Form -->
        <form @submit.prevent="submitForm" class="mt-6">
          <div>
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <input
              id="name"
              v-model="formData.name"
              type="text"
              required
              class="mt-1 block w-full border-gray-300 text-sm rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div class="mt-4">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              v-model="formData.email"
              type="email"
              required
              class="mt-1 block w-full border-gray-300 text-sm rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div class="mt-4">
            <label for="message" class="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              id="message"
              v-model="formData.message"
              required rows="5"
              class="mt-1 block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            ></textarea>
          </div>
          <div class="mt-6">
            <button
              type="submit"
              class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send Message
            </button>
          </div>
        </form>

        <!-- Feedback Messages -->
        <div v-if="successMessage" class="mt-4 text-green-600">
          {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="mt-4 text-red-600">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>
