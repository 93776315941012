<template>
    <div class="min-h-screen bg-indigo-500">
        <!-- Reuse your existing dashboard nav, if you want -->
        <DashboardNav />

        <div class="max-w-5xl mx-auto py-8 px-4">
            <h1 class="text-2xl font-semibold mb-6 text-gray-100">Add / Edit Property</h1>

            <!-- Error Alert -->
            <div v-if="error" class="mb-4 bg-red-100 border border-red-200 text-red-700 px-4 py-3 rounded">
                {{ error }}
            </div>

            <form @submit.prevent="handleSubmit" class="bg-white rounded shadow p-6 space-y-6">
                <!-- Name and Description -->
                <div class="flex flex-col md:flex-row gap-6">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Property Name <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="name" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200"
                            placeholder="e.g. Harbour View Motel" />
                    </div>
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Type <span class="text-red-500">*</span>
                        </label>
                        <select v-model="type" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200">
                            <option disabled value="">Choose a type</option>
                            <option v-for="opt in propertyTypes" :key="opt" :value="opt">
                                {{ opt }}
                            </option>
                        </select>
                    </div>
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                        Description <span class="text-red-500">*</span>
                    </label>
                    <textarea v-model="description" rows="3" required
                        class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        placeholder="Brief description of the property..."></textarea>
                </div>

                <!-- Address Autocomplete -->
                <div class="relative">
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                        Search Address (Autocomplete)
                    </label>
                    <input type="text" v-model="addressSearch" @input="debounceSearchAddress"
                        class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        placeholder="e.g. 359 Goonoo Goonoo Road..." />
                    <!-- Autocomplete results -->
                    <ul v-if="showSuggestions && addressOptions.length"
                        class="absolute left-0 right-0 mt-1 bg-white border border-gray-200 rounded shadow z-10">
                        <li v-for="(opt, idx) in addressOptions" :key="idx" @click="selectAddress(opt)"
                            class="p-2 hover:bg-gray-100 cursor-pointer">
                            {{ opt.description }}
                        </li>
                    </ul>
                </div>

                <!-- Address Fields -->
                <div class="grid md:grid-cols-2 gap-4">
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Address Line 1 <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="address_line_1" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Address Line 2
                        </label>
                        <input type="text" v-model="address_line_2"
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            City/Suburb <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="city" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            State/Region <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="state" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Zip/Postcode <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="zip" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Country <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="country" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                </div>

                <!-- Google Map & lat/lon -->
                <div class="grid md:grid-cols-2 gap-4">
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Latitude <span class="text-red-500">*</span>
                        </label>
                        <input type="number" step="any" v-model="latitude" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Longitude <span class="text-red-500">*</span>
                        </label>
                        <input type="number" step="any" v-model="longitude" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                </div>
                <!-- (Optional) A small map embed or placeholder if you want to show a pin -->
                <div class="bg-gray-200 h-48 flex items-center justify-center text-gray-500">
                    <!-- Could load Google Maps or Leaflet. For now, just a placeholder. -->
                    <span>Map preview goes here</span>
                </div>

                <!-- Timezone, ABN row -->
                <div class="grid md:grid-cols-2 gap-4">
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Timezone <span class="text-red-500">*</span>
                        </label>
                        <input type="text" v-model="timezone" required
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200"
                            placeholder="e.g. Australia/Sydney" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            ABN (Optional)
                        </label>
                        <input type="text" v-model="abn"
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200" />
                    </div>
                </div>

                <!-- Tax Rate and Calculation -->
                <div class="grid md:grid-cols-2 gap-4">
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Tax Rate (GST) (Fixed @ 10%)
                        </label>
                        <!-- We set it to 1000 always per your requirement. -->
                        <input type="number" v-model="tax_rate" disabled
                            class="w-full border border-gray-300 rounded p-2 bg-gray-100 text-gray-600 cursor-not-allowed" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">
                            Are your rates inclusive or exclusive of GST?
                        </label>
                        <select v-model="tax_calculation"
                            class="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-200">
                            <option value="inclusive">Inclusive</option>
                            <option value="exclusive">Exclusive</option>
                        </select>
                    </div>
                </div>

                <!-- Submit Button -->
                <div class="pt-4">
                    <button type="submit" :disabled="loading"
                        class="px-6 py-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 transition disabled:opacity-50">
                        {{ loading ? 'Saving...' : 'Save Property' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import DashboardNav from '@/components/DashboardNav.vue'
import { useAuthStore } from '@/stores/auth'
import { api } from '@/lib/api'
import { useRouter } from 'vue-router'

// A property can be:
const propertyTypes = [
    'motel',
    'hotel',
    'resort',
    'tourist park',
    'bed and breakfast',
    'apartment/unit/villa',
    'hostel',
    'campground',
    'guesthouse',
    'private house'
]

const authStore = useAuthStore()
const router = useRouter()

const error = ref('')
const loading = ref(false)

// Fields
const name = ref('')
const description = ref('')
const type = ref('')
const address_line_1 = ref('')
const address_line_2 = ref('')
const city = ref('')
const state = ref('')
const zip = ref('')
const country = ref('')
const timezone = ref('Australia/Sydney')
const abn = ref('')
const tax_rate = ref(1000) // fixed to 10%
const tax_calculation = ref<'inclusive' | 'exclusive'>('inclusive')
const latitude = ref(-33.8688)  // example default
const longitude = ref(151.2093) // example default

// For the address search
const addressSearch = ref('')
const addressOptions = ref<any[]>([])
const showSuggestions = ref(false)
let searchTimeout: any = null

// Debounce
function debounceSearchAddress() {
    if (!addressSearch.value) {
        addressOptions.value = []
        showSuggestions.value = false
        return
    }
    if (searchTimeout) clearTimeout(searchTimeout)
    searchTimeout = setTimeout(async () => {
        try {
            const query = addressSearch.value.trim()
            if (!query) return
            const response = await api.get(`/address/search`, {
                params: { address: query }
            })
            // shape: { status: 'success', predictions: [{ description, place_id, search }, ...] }
            addressOptions.value = response.data.predictions ?? []
            showSuggestions.value = true
        } catch (err) {
            console.error(err)
            addressOptions.value = []
            showSuggestions.value = false
        }
    }, 300)
}

// If user selects an address from the dropdown
function selectAddress(item: any) {
    addressSearch.value = item.description
    showSuggestions.value = false

    // For demonstration, we can parse a basic pattern if item.search is like:
    // "359 Goonoo Goonoo Road, Tamworth, NSW, Australia"
    // We might do something naive:
    const parts = item.search.split(',')
    if (parts.length >= 5) {
        address_line_1.value = parts[0].trim() + ' ' + parts[1].trim()  // "359 Goonoo Goonoo Road"
        city.value = parts[2].trim()            // "Tamworth"
        state.value = parts[3].trim()           // "NSW"
        country.value = parts[4].trim()         // "Australia"
    }
    else if (parts.length == 4) {
        address_line_1.value = parts[0].trim() // "359 Goonoo Goonoo Road"
        city.value = parts[1].trim()            // "Tamworth"
        state.value = parts[2].trim()           // "NSW"
        country.value = parts[3].trim()         // "Australia"
    }

    // Optionally call Google or your server again to get lat/lon
    // For now, we just do a placeholder
    latitude.value = -31.0833
    longitude.value = 150.9167
}

// Submit
async function handleSubmit() {
    loading.value = true
    error.value = ''

    try {
        const payload = {
            name: name.value,
            description: description.value,
            type: type.value,
            address_line_1: address_line_1.value,
            address_line_2: address_line_2.value || '',
            city: city.value,
            state: state.value,
            zip: zip.value,
            country: country.value,
            timezone: timezone.value,
            abn: abn.value || '',
            tax_rate: tax_rate.value,              // always 1000
            tax_calculation: tax_calculation.value,// 'inclusive' or 'exclusive'
            latitude: latitude.value,
            longitude: longitude.value
        }

        const response = await api.post('/properties', payload)

        console.log('HTTP status:', response.status);    // Should see 201
        console.log('Response data:', response.data);

        // Suppose response.data = { data: {... new property object ...} }
        const newPropertyData = response.data.data
        const newProperty = {
            id: newPropertyData.id,
            ...newPropertyData.attributes
        }

        // Update store
        if (!authStore.properties) {
            authStore.properties = []
        }
        authStore.properties.push(newProperty)
        authStore.setSelectedProperty(newProperty.id)

        // Possibly navigate to dashboard or wherever
        router.push('/dashboard')
    } catch (err: any) {
        console.error(err)
        error.value = err?.response?.data?.message || 'Failed to save property.'
    } finally {
        loading.value = false
    }
}
</script>

<style scoped>
/* Minimal local styling. Rely primarily on Tailwind classes. */
</style>
