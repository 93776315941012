<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div class="bg-white rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
            <div class="p-6 space-y-4">
                <h2 class="text-xl font-semibold">Edit Listing</h2>

                <div v-if="error" class="bg-red-100 text-red-700 border border-red-200 p-2">
                    {{ error }}
                </div>

                <form @submit.prevent="updateListing" class="space-y-3">
                    <!-- Type -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Type</label>
                        <input type="text" v-model="type" class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                    </div>

                    <!-- Name -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Name</label>
                        <input type="text" v-model="name" class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                    </div>

                    <!-- Listing Number -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Listing Number</label>
                        <input type="text" v-model="listingNumber"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                    </div>

                    <!-- Description -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea v-model="description" rows="2"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                    </div>

                    <!-- Beds, Max Guests row -->
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Beds</label>
                            <input type="number" v-model="beds"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Max Guests</label>
                            <input type="number" v-model="maximumGuests"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Max Adults</label>
                            <input type="number" v-model="maximumAdults"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Max Children</label>
                            <input type="number" v-model="maximumChildren"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="flex justify-end space-x-3 pt-2">
                        <button type="button" @click="$emit('close')"
                            class="px-4 py-2 border border-gray-300 rounded text-gray-700">
                            Cancel
                        </button>
                        <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { api } from '@/lib/api'
import { useAuthStore } from '@/stores/auth'

const auth = useAuthStore()

interface ListingAttributes {
    id: number
    type: string
    name: string
    listingNumber: string
    description: string
    beds: number
    maximumGuests: number
    maximumAdults: number
    maximumChildren: number
}

interface Props {
    listing: {
        ListingAttributes
    } | null
}

const props = defineProps<Props>()
const emit = defineEmits(['close', 'updated'])

const error = ref('')
const type = ref('')
const name = ref('')
const listingNumber = ref('')
const description = ref('')
const beds = ref<number>(1)
const maximumGuests = ref<number>(2)
const maximumAdults = ref<number>(2)
const maximumChildren = ref<number>(0)

// On mount, fill the fields
onMounted(() => {
    if (props.listing) {
        type.value = props.listing.attributes.type
        name.value = props.listing.attributes.name
        listingNumber.value = props.listing.attributes.listingNumber
        description.value = props.listing.attributes.description
        beds.value = props.listing.attributes.beds
        maximumGuests.value = props.listing.attributes.maximumGuests
        maximumAdults.value = props.listing.attributes.maximumAdults
        maximumChildren.value = props.listing.attributes.maximumChildren
    }
})

async function updateListing() {
    if (!props.listing) {
        error.value = 'No listing to edit.'
        return
    }
    error.value = ''

    try {
        // PATCH /listings/:id
        const payload = {
            property_id: auth.selectedProperty,
            type: type.value,
            name: name.value,
            identifier: listingNumber.value,
            description: description.value,
            beds: beds.value,
            max_guests: maximumGuests.value,
            max_adults: maximumAdults.value,
            max_children: maximumChildren.value
        }
        await api.patch(`/listings/${props.listing.id}`, payload)
        emit('updated')
    } catch (err: any) {
        console.error('Failed to update listing:', err)
        error.value = err?.response?.data?.message || 'Failed to update listing.'
    }
}
</script>
