<template>
    <div class="min-h-screen bg-indigo-500">
        <DashboardNav />

        <div class="max-w-7xl mx-auto py-8 px-4">
            <h1 class="text-2xl font-semibold text-gray-100 mb-6">
                Listings for {{ currentProperty?.name || '...' }}
            </h1>

            <!-- If no property selected -->
            <div v-if="!currentProperty">
                <p class="text-yellow-400">
                    No property selected. Please select or create a property first.
                </p>
            </div>

            <!-- If property is selected -->
            <div v-else>
                <!-- Bulk create button -->
                <button class="mb-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 mr-2"
                    @click="showBulkModal = true">
                    Bulk Create Listings
                </button>

                <!-- Single listing create button (optional) -->
                <button class="mb-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                    @click="showCreateModal = true">
                    + New Listing
                </button>

                <!-- Loading indicator -->
                <div v-if="loading" class="text-gray-600">
                    Loading listings...
                </div>

                <!-- Listings grid -->
                <div v-else-if="listings && listings.length"
                    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div v-for="(lst, idx) in listings" :key="lst.id" class="bg-white rounded shadow p-4">
                        <h2 class="text-lg font-semibold mb-1">
                            {{ lst.attributes?.name }} ({{ lst.attributes?.listingNumber }})
                        </h2>
                        <p class="text-gray-600 text-sm mb-2">
                            {{ lst.attributes?.description }}
                        </p>
                        <p class="text-sm">
                            Beds: {{ lst.attributes?.beds }}<br />
                            Max Guests: {{ lst.attributes?.maximumGuests }}<br />
                            Max Adults: {{ lst.attributes?.maximumAdults }}<br />
                            Max Children: {{ lst.attributes?.maximumChildren }}
                        </p>
                        <!-- Example actions or editing links -->
                        <div class="mt-3 flex space-x-2">
                            <button @click="editListing(lst)"
                                class="px-3 py-1 bg-yellow-400 text-white rounded hover:bg-yellow-500 text-sm">
                                Edit
                            </button>
                            <button @click="deleteListing(lst)"
                                class="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-sm">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>

                <!-- No listings found -->
                <p v-else class="text-gray-500 italic">
                    No listings found for this property.
                </p>
            </div>
        </div>

        <!-- Bulk Create Modal -->
        <BulkListingsModal v-if="showBulkModal" :propertyId="currentProperty?.id" @close="showBulkModal = false"
            @created="handleModalRefresh" />

        <!-- Single Create Listing Modal -->
        <CreateListingModal v-if="showCreateModal" :propertyId="currentProperty?.id" @close="showCreateModal = false"
            @created="handleModalRefresh" />

        <!-- Edit Listing Modal -->
        <EditListingModal v-if="showEditModal" :listing="editItem" @close="showEditModal = false"
            @updated="handleModalRefresh" />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import DashboardNav from '@/components/DashboardNav.vue'
import { useAuthStore } from '@/stores/auth'
import { api } from '@/lib/api'

// Two child modals (examples below)
import BulkListingsModal from '@/components/BulkListingsModal.vue'
import CreateListingModal from '@/components/CreateListingModal.vue'
import EditListingModal from '@/components/EditListingModal.vue'

const authStore = useAuthStore()

const currentProperty = computed(() => authStore.propertyInformation)
const listings = ref<any[]>([])
const loading = ref(false)

const showBulkModal = ref(false)
const showCreateModal = ref(false)
const showEditModal = ref(false)
const editItem = ref<any>(null)


// Refresh listings from the API
async function loadListings() {
    if (!currentProperty.value?.id) return
    loading.value = true
    try {
        const response = await api.get(`/properties/${currentProperty.value.id}`)
        listings.value = response.data.data.relationships?.listings || []
    } catch (err) {
        console.error('Failed to load listings:', err)
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    loadListings()
})

// Called whenever we close a modal after creation
function handleModalRefresh() {
    showBulkModal.value = false
    showCreateModal.value = false
    showEditModal.value = false
    loadListings()
}

function editListing(lst: any) {
    // Example: open a dedicated modal or route
    console.log('TODO: edit listing', lst.id)
    editItem.value = lst   // store the listing object
    showEditModal.value = true
}

async function deleteListing(lst: any) {
    if (!confirm(`Are you sure you want to delete listing: ${lst.attributes?.name}?`)) return
    try {
        await api.delete(`/listings/${lst.id}`)
        listings.value = listings.value.filter(l => l.id !== lst.id)
    } catch (err) {
        console.error('Failed to delete listing:', err)
    }
}
</script>

<style scoped>
/* Minimal local styling */
</style>
