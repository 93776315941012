<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div class="bg-white rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
            <div class="p-6 space-y-4">
                <h2 class="text-xl font-semibold">New Listing Fee</h2>

                <div v-if="error" class="bg-red-100 text-red-700 border border-red-200 p-2">
                    {{ error }}
                </div>

                <form @submit.prevent="createFee" class="space-y-3">
                    <!-- Type -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Listing Type</label>
                        <input type="text" v-model="type"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                            placeholder="e.g. family_room" />
                    </div>

                    <!-- Rate -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Rate (per night)</label>
                        <input type="number" step="0.01" v-model="rate"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                            placeholder="e.g. 125.00" />
                    </div>

                    <!-- Buttons -->
                    <div class="flex justify-end space-x-3 pt-2">
                        <button type="button" @click="$emit('close')"
                            class="px-4 py-2 border border-gray-300 rounded text-gray-700">
                            Cancel
                        </button>
                        <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { api } from '@/lib/api'

interface Props {
    propertyId: number | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['close', 'created'])

const error = ref('')
const type = ref('')
const rate = ref<number | null>(null)

async function createFee() {
    if (!props.propertyId) {
        error.value = 'No property selected.'
        return
    }
    error.value = ''

    try {
        // Example: POST /fees/listing
        const payload = {
            property_id: props.propertyId,
            type: type.value,
            // Convert to cents if your API requires
            default_rate: rate.value ? Math.round(rate.value * 100) : 0
        }
        await api.post('/fees/listing', payload)
        emit('created') // parent can refresh
    } catch (err: any) {
        console.error('Failed to create listing fee:', err)
        error.value = err?.response?.data?.message || 'Failed to create listing fee.'
    }
}
</script>
