// src/stores/auth.ts
import { defineStore } from 'pinia';
import { api } from '@/lib/api';
import router from '@/router/index';
import { Property, User } from '@/types'

interface AuthState {
    token: string | null
    refresh_token: string | null
    user: User | null
    properties: Property[] | null
    selectedProperty: number | null
    loading: boolean
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        token: localStorage.getItem('token'),
        refresh_token: localStorage.getItem('refresh_token'),
        selectedProperty: parseInt(localStorage.getItem('selectedProperty') ?? "0"),
        user: null,
        properties: null,
        loading: false
    }),

    getters: {
        // This getter checks both for a token AND a user object
        // This provides stronger authentication checking since it ensures
        // we have both valid credentials and user data
        isAuthenticated: (state: AuthState): boolean => {
            return !!state.token && !!state.user;
        },

        // Property information getter
        propertyInformation: (state: AuthState): Property | null => {
            // First try to find the selected property
            const selectedProperty = state.properties?.find(
                property => property.id === state.selectedProperty
            );
            
            // If no selected property found, fall back to first property
            // If no properties at all, return null
            return selectedProperty ?? state.properties?.[0] ?? null;
        },
    },

    actions: {
        async initializeAuth() {
            // Call this on app startup or after refresh
            if (this.token) {
                try {
                    // Get user data
                    const userResponse = await api.get('/me');
                    this.user = userResponse.data;

                    // Get properties
                    const propertyResponse = await api.get('/properties');
                    this.properties = propertyResponse.data.data.map((property: any) => ({
                        id: property.id,
                        ...property.attributes,
                    }));

                    // Set selected property from localStorage or default to first
                    const storedPropertyId = localStorage.getItem('selectedProperty');
                    if (storedPropertyId) {
                        this.setSelectedProperty(parseInt(storedPropertyId));
                    } else if (this.properties?.length) {
                        this.setSelectedProperty(this.properties[0].id);
                    }
                } catch (error) {
                    // If initialization fails, clear auth state
                    this.logout();
                }
            }
        },

        async login(email: string, password: string) {
            this.loading = true;
            try {
                const response = await api.post('/login', { email, password });
                
                // Set tokens
                this.setTokens(response.data.access_token, response.data.refresh_token);
                
                // Initialize user and properties
                await this.initializeAuth();
                
                return true
            } catch (error: any) {
                throw error?.response?.data?.message || 'Login failed';
            } finally {
                this.loading = false;
            }
        },

        setTokens(accessToken: string, refreshToken: string | null) {
            this.token = accessToken;
            this.refresh_token = refreshToken;
            localStorage.setItem('token', accessToken);
            if (refreshToken) {
                localStorage.setItem('refresh_token', refreshToken);
            }
        },

        setSelectedProperty(propertyId: number) {
            this.selectedProperty = propertyId;
            localStorage.setItem('selectedProperty', propertyId.toString());
        },

        logout() {
            this.token = null;
            this.refresh_token = null;
            this.user = null;
            this.properties = null;
            this.selectedProperty = null;
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('selectedProperty');
            router.push('/login');
        }
    }
});
