
<script setup lang="ts">
import { ref } from 'vue'
import { api } from '@/lib/api'

interface Props {
    propertyId: number | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['close', 'created'])

const error = ref('')
const type = ref('')
const trigger = ref('')
const triggerPercentage = ref(false)
const rateVal = ref<number | null>(null)
const isPercentage = ref(false)

async function createOccupancy() {
    if (!props.propertyId) {
        error.value = 'No property selected.'
        return
    }
    error.value = ''

    try {
        // POST /rates/occupancy
        const payload = {
            property_id: props.propertyId,
            type: type.value,
            trigger_occupancy: trigger.value,
            trigger_percentage: triggerPercentage.value,
            is_percentage: isPercentage.value,
            adjustment_amount: rateVal.value
        }
        await api.post('/rates/occupancy', payload)
        emit('created')
    } catch (err: any) {
        console.error('Failed to create occupancy rate:', err)
        error.value = err?.response?.data?.message || 'Failed to create occupancy rate.'
    }
}
</script>

<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div class="bg-white rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
            <div class="p-6 space-y-4">
                <h2 class="text-xl font-semibold">New Occupancy Rate</h2>

                <div v-if="error" class="bg-red-100 text-red-700 border border-red-200 p-2">
                    {{ error }}
                </div>

                <form @submit.prevent="createOccupancy" class="space-y-3">
                    <!-- Type -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Listing Type</label>
                        <input type="text" v-model="type" class="mt-1 border border-gray-300 rounded p-1.5 w-full"
                            placeholder="e.g. family_room" />
                    </div>

                    <!-- Trigger -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Trigger</label>
                        <input type="text" v-model="trigger" class="mt-1 border border-gray-300 rounded p-1.5 w-full"
                            placeholder="e.g. 50% or '5 rooms sold' etc." />
                    </div>

                    <div class="flex items-center gap-2">
                        <input id="triggerPct" type="checkbox" v-model="triggerPercentage"
                            class="h-4 w-4 text-blue-600" />
                        <label for="triggerPct" class="text-sm text-gray-700">Trigger is %?</label>
                    </div>

                    <!-- Rate + isPercentage? -->
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Rate (must use whole numbers for percentages)</label>
                            <input type="number" step="1" v-model="rateVal"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full"
                                placeholder="10 for 10% or $10" />
                        </div>
                        <div class="flex items-center gap-2 mt-5">
                            <input id="pct" type="checkbox" v-model="isPercentage" class="h-4 w-4 text-blue-600" />
                            <label for="pct" class="text-sm text-gray-700">Rate is %?</label>
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="flex justify-end space-x-3 pt-2">
                        <button type="button" @click="$emit('close')"
                            class="px-4 py-2 border border-gray-300 rounded text-gray-700">
                            Cancel
                        </button>
                        <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
