<script setup>
import { ref, onBeforeUnmount, computed } from 'vue'
import { useRouter } from 'vue-router'
import DashboardNav from '@/components/DashboardNav.vue'
import ComingSoonBanner from './ComingSoonBanner.vue'

// FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Footer from './Footer.vue'

// Assets & API
import logoUrl from '@/assets/ozreservations.png'
import api from '@/lib/publicapi'
import { useAuthStore } from '@/stores/auth'

// Import Luxon for date management
import { DateTime } from 'luxon'

const router = useRouter()

// Set up default dates using Luxon: check-in is today; check-out is tomorrow.
const today = DateTime.local().startOf('day')
const tomorrow = today.plus({ days: 1 })

// State variables
const searchLocation = ref('')
const checkIn = ref(today.toISODate())
const checkOut = ref(tomorrow.toISODate())
const rooms = ref(1)
const adults = ref(2)
const children = ref(0)
const logoUrlRef = ref(logoUrl)

const locationOptions = ref([])
const showSuggestions = ref(false)

let debounceTimer = null

// Debounce location search (for autocomplete)
async function debounceSearchLocation() {
  if (!searchLocation.value.trim()) {
    locationOptions.value = []
    showSuggestions.value = false
    return
  }
  if (debounceTimer) clearTimeout(debounceTimer)

  debounceTimer = setTimeout(async () => {
    try {
      const query = searchLocation.value.trim()
      const response = await api.searchLocations(query)
      locationOptions.value = response.data.predictions || []
      showSuggestions.value = true
    } catch (error) {
      console.error('Error fetching location suggestions:', error)
      locationOptions.value = []
      showSuggestions.value = false
    }
  }, 300)
}

// When a suggestion is selected
function selectLocation(opt) {
  searchLocation.value = opt.search
  showSuggestions.value = false
}

// Validate dates: check-in must be today or later; check-out must be after check-in.
const datesValid = computed(() => {
  const checkInDate = DateTime.fromISO(checkIn.value)
  const checkOutDate = DateTime.fromISO(checkOut.value)
  return checkInDate >= today && checkOutDate > checkInDate
})

// Perform search if dates are valid
function searchStays() {
  if (!datesValid.value) {
    alert('Please select valid dates: Check-in must be today or later and Check-out must be after Check-in.')
    return
  }

  if (!searchLocation.value || searchLocation.value === '') {
    alert('Please type in a location to search for')
    return
  }

  const locationParts = searchLocation.value.split(',').map((part) => part.trim())
  const [city, region] = locationParts

  // TODO: Max number of adults, children and rooms we will accept
  // Also, the dates have to make sense, cannot book more than 500 days in future or similar

  router.push({
    name: 'SearchResults',
    query: {
      city: city || '',
      region: region || '',
      check_in: checkIn.value,
      check_out: checkOut.value,
      adults: adults.value,
      children: children.value,
      rooms: rooms.value
    }
  })
}

onBeforeUnmount(() => {
  if (debounceTimer) clearTimeout(debounceTimer)
})
</script>

<template>
  <div>
    <!-- Navigation / Banner -->
    <DashboardNav v-if="auth?.isAuthenticated" />
    <ComingSoonBanner v-else />

    <!-- Main Grid: Hero & Search Form -->
    <div class="grid md:grid-cols-2 gap-8 min-h-[calc(100vh-200px)] p-8 max-w-6xl mx-auto">
      <!-- Left Column: Hero Section -->
      <div class="flex items-center justify-center">
        <div class="text-center max-w-md relative">
          <img class="mx-auto mb-8 w-[250px] h-auto" :src="logoUrl" alt="OzReservations Logo" />
          <h1 class="text-4xl font-bold mb-4 text-blue-600 drop-shadow">
            Find Your Perfect Australian Getaway
          </h1>
          <p class="text-lg text-gray-600">
            From coastal paradise to outback adventures
          </p>
          <!-- Decorative line under hero text -->
          <div class="h-[3px] w-16 bg-yellow-500 mt-6 mx-auto rounded"></div>
        </div>
      </div>

      <!-- Right Column: Search Form Card -->
      <div>
        <div class="bg-white rounded-xl shadow p-6">
          <!-- Title / Header -->
          <div class="flex items-center gap-2 text-blue-600 text-xl mb-6">
            <font-awesome-icon :icon="faSearch" />
            <span>Search Accommodation</span>
          </div>

          <!-- Form -->
          <form @submit.prevent="searchStays" class="flex flex-col gap-6">
            <!-- Location Autocomplete -->
            <div class="flex flex-col gap-1">
              <label for="location" class="text-sm font-medium text-gray-700">
                Where would you like to stay?
              </label>
              <div class="relative">
                <!-- The text input -->
                <input
                  type="text"
                  v-model="searchLocation"
                  @input="debounceSearchLocation"
                  placeholder="Enter city or region..."
                  class="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-200"
                />

                <!-- Autocomplete Suggestions -->
                <ul
                  v-if="showSuggestions && locationOptions.length"
                  class="absolute left-0 right-0 mt-1 bg-white border border-gray-200 rounded shadow z-10"
                >
                  <li
                    v-for="(opt, idx) in locationOptions"
                    :key="opt.place_id"
                    @click="selectLocation(opt)"
                    class="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                  >
                    <font-awesome-icon :icon="faMapMarkerAlt" class="text-gray-500" />
                    {{ opt.search }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- Date Range -->
            <div class="flex flex-col gap-1">
              <label class="text-sm font-medium text-gray-700">
                When are you traveling?
              </label>
              <!-- Responsive grid: one column on very small screens, two columns on sm+ -->
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <input
                  type="date"
                  v-model="checkIn"
                  class="w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
                />
                <input
                  type="date"
                  v-model="checkOut"
                  class="w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
                />
              </div>
            </div>

            <!-- Rooms / Adults / Children -->
            <div class="grid grid-cols-3 gap-4">
              <div class="flex flex-col gap-1">
                <label class="text-sm font-medium text-gray-700">Rooms</label>
                <input
                  type="number"
                  min="1"
                  v-model="rooms"
                  class="border border-gray-300 rounded-md p-2 text-center focus:ring focus:ring-blue-200"
                />
              </div>
              <div class="flex flex-col gap-1">
                <label class="text-sm font-medium text-gray-700">Adults</label>
                <input
                  type="number"
                  min="1"
                  v-model="adults"
                  class="border border-gray-300 rounded-md p-2 text-center focus:ring focus:ring-blue-200"
                />
              </div>
              <div class="flex flex-col gap-1">
                <label class="text-sm font-medium text-gray-700">Children</label>
                <input
                  type="number"
                  min="0"
                  v-model="children"
                  class="border border-gray-300 rounded-md p-2 text-center focus:ring focus:ring-blue-200"
                />
              </div>
            </div>

            <!-- Submit -->
            <button
              type="submit"
              class="bg-blue-600 text-white font-semibold py-3 rounded-md flex items-center justify-center gap-2 hover:bg-blue-700 transition w-full"
            >
              <font-awesome-icon :icon="faSearch" />
              Search Stays
            </button>
          </form>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<style scoped>
/* No additional CSS beyond Tailwind */
</style>
