<template>
  <!-- Outer container: fixed, on top, and clips overflowing parts -->
  <div class="fixed inset-0 z-50 pointer-events-none overflow-hidden">
    <!-- Rotated banner container:
         - Its transform origin is the bottom-left.
         - It is moved down by 33vh and rotated -45°.
         - Its size (200vw by 5rem) is large so that after rotation the visible portion covers from the left to the top.
    -->
    <div
      class="absolute transform origin-bottom-left"
      style="transform: translateY(33vh) rotate(-45deg); width: 200vw; height: 5rem;"
    >
      <!-- The banner’s background; relative so that inner absolute positioning is relative to it -->
      <div class="relative w-full h-full bg-red-600">
        <!-- 
          Absolutely position the text.
          Adjust "left-8" (roughly 2rem from the left) so that it lands inside the visible stripe.
          "top-1/2" with "-translate-y-1/2" centers it vertically within the 5rem-high banner.
        -->
        <span
          class="absolute left-32 top-1/2 transform -translate-y-1/2 text-white text-lg font-bold uppercase tracking-wider"
        >
          Coming Soon
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>
