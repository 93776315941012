<!-- src/components/DashboardNav.vue -->
<template>
  <nav class="bg-white shadow-lg">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">

        <!-- Left side: existing nav items -->
        <div class="flex space-x-8">
          <router-link v-for="item in navItems" :key="item.path" :to="item.path"
            class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium" :class="[
              $route.path === item.path
                ? 'border-yellow-500 text-gray-900'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            ]">
            {{ item.name }}
          </router-link>
        </div>

        <!-- Right side: property dropdown + logout button -->
        <div class="flex items-center space-x-4">

          <!-- Only show the dropdown if there are properties -->
          <div v-if="properties && properties.length > 1" class="relative">
            <label for="propertyDropdown" class="sr-only">Choose Property</label>
            <select id="propertyDropdown" v-model="selectedPropertyId" @change="handlePropertyChange"
              class="border border-gray-300 rounded-md text-gray-700 py-1 px-2">
              <option v-for="prop in properties" :key="prop.id" :value="prop.id">
                {{ prop.name }}
              </option>
            </select>
          </div>

          <!-- Logout Button -->
          <button @click="handleLogout" class="inline-flex items-center px-3 py-1 my-auto border border-transparent 
                   text-sm font-medium rounded-md text-white bg-red-600 
                   hover:bg-red-700 focus:outline-none focus:ring-2 
                   focus:ring-offset-2 focus:ring-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 
                   1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 
                   010-1.414l3-3a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            Logout
          </button>

        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import logo from '@/assets/ozreservations.png'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Pull in the store
const auth = useAuthStore()
const router = useRouter()

const isMenuOpen = ref(false)

const menuItems = [
  { name: 'Manager Login', path: '/manager-login' },
  { name: 'Sign Up', path: '/signup' },
  { name: 'About Us', path: '/about' },
  { name: 'Get in touch', path: '/contact' }
]

const handleMenuClick = (path) => {
  isMenuOpen.value = false
  router.push(path)
}
const handleClickOutside = (event) => {
  const dropdown = document.querySelector('.dropdown-menu')
  const button = document.querySelector('.menu-button')
  if (isMenuOpen.value && dropdown && !dropdown.contains(event.target) && !button.contains(event.target)) {
    isMenuOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})

// We can destructure reactive refs from the store to easily bind them
const { properties, selectedProperty } = storeToRefs(auth)

// We'll keep a local computed for the property ID
// so we can v-model it in the <select>.
const selectedPropertyId = computed({
  get() {
    return selectedProperty.value
  },
  set(value: number) {
    selectedProperty.value = value
  }
})

function handlePropertyChange() {
  // This function runs whenever the <select> changes
  // (though since we’re v-modeling, you could also do the same in a watch)
  if (selectedPropertyId.value !== null) {
    auth.setSelectedProperty(selectedPropertyId.value)
    // Optionally do any immediate re‐fetch or router updates here
    // (Usually we let each page watch the change though.)
    // e.g. router.push('/dashboard') if needed
  }
}

function handleLogout() {
  auth.logout()
  router.push('/')
}

// Example nav items
const navItems = [
  { name: 'Dashboard', path: '/dashboard' },
  { name: 'Bookings', path: '/bookings' },
  { name: 'Settings', path: '/settings' },
  { name: 'Listings', path: '/listings' },
  { name: 'Rates', path: '/rates' },
  { name: 'Guests', path: '/guests' },
]

console.info('DashboardNav loaded')
console.log('Current user:', auth.user)

</script>
