<template>
    <div class="min-h-screen bg-indigo-500">
        <DashboardNav />

        <div class="max-w-7xl mx-auto py-8 px-4">
            <h1 class="text-2xl font-semibold text-gray-100 mb-6">Guests</h1>

            <!-- Sorting controls -->
            <div class="flex items-center space-x-2 mb-4">
                <label class="text-sm font-medium text-gray-100">Sort By:</label>
                <select v-model="sortBy" class="border border-gray-300 rounded p-1 focus:ring focus:ring-blue-200">
                    <option value="last_name">Last Name</option>
                    <option value="guest_number">Guest Number</option>
                    <option value="email">Email</option>
                </select>
            </div>

            <!-- Autocomplete + Clear button -->
            <div class="relative mb-4 w-full max-w-sm">
                <label class="block text-sm font-medium text-gray-100 mb-1">Find a Guest</label>
                <div class="flex items-center space-x-2">
                    <input type="text" v-model="searchTerm" @input="debounceSearch"
                        class="flex-1 border border-gray-300 rounded p-1.5 focus:ring focus:ring-blue-200"
                        placeholder="e.g. 'Smith' or '10912'" />
                    <!-- Show clear button if searchTerm is not empty -->
                    <button v-if="searchTerm" @click="clearSearch" class="text-gray-600 hover:text-gray-800"
                        title="Clear search">
                        X
                    </button>
                </div>
            </div>

            <!-- Loading state -->
            <div v-if="loading" class="text-gray-600 mb-4">
                Loading guests...
            </div>

            <!-- If we are in search mode and the array is empty => "No results" -->
            <div v-else-if="inSearchMode && guestsRaw.length === 0">
                <p class="italic text-gray-500">
                    No results for "{{ searchTerm }}"
                </p>
            </div>

            <!-- Otherwise, show the table if we have any guests -->
            <div v-else class="bg-white shadow rounded overflow-x-auto">
                <table class="min-w-full text-left">
                    <thead class="bg-gray-50">
                        <tr>
                            <th class="px-4 py-2">Guest #</th>
                            <th class="px-4 py-2">Last Name</th>
                            <th class="px-4 py-2">First Name</th>
                            <th class="px-4 py-2">Email</th>
                            <th class="px-4 py-2">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="guest in sortedGuests" :key="guest.id" class="border-b last:border-none">
                            <td class="px-4 py-2">{{ guest.guest_number }}</td>
                            <td class="px-4 py-2">{{ guest.last_name }}</td>
                            <td class="px-4 py-2">{{ guest.first_name }}</td>
                            <td class="px-4 py-2">{{ guest.email }}</td>
                            <td class="px-4 py-2">{{ guest.phone }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Pagination controls => Only if NOT in search mode + meta exists + more than 1 page -->
            <div class="mt-4 flex justify-center items-center space-x-1"
                v-if="!inSearchMode && meta && meta.last_page > 1">
                <!-- We'll loop over meta.links -->
                <div v-for="(link, i) in meta.links" :key="i">
                    <!-- If link.url is null, show a disabled item -->
                    <span v-if="!link.url"
                        class="px-3 py-1 border border-gray-300 rounded bg-gray-100 text-gray-500 cursor-default"
                        v-html="link.label"></span>

                    <!-- If link.url is valid, show a clickable button -->
                    <button v-else @click="goToPageFromUrl(link.url)" :class="[
                        'px-3 py-1 border border-gray-300 rounded hover:bg-gray-50',
                        link.active ? 'bg-blue-100' : ''
                    ]" v-html="link.label"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import DashboardNav from '@/components/DashboardNav.vue'
import { api } from '@/lib/api'

interface Guest {
    id: number
    guest_number: number
    first_name: string
    last_name: string
    email: string
    phone: string
    // etc.
}

interface GuestAttributes {
    firstName: string
    lastName: string
    phone: string
    email: string
    guestNumber: number
    memberNumber: string | null
    memberType: string | null
}
interface GuestResponse {
    id: number
    attributes: GuestAttributes
}

const loading = ref(false)
const guestsRaw = ref<Guest[]>([])

// For pagination
const meta = ref<any>(null)
const currentUrl = ref('/guests')

// For search mode
const searchTerm = ref('')
let debounceTimer: number | null = null
const inSearchMode = ref(false) // Are we currently displaying search results?

// Sorting
const sortBy = ref<'last_name' | 'guest_number' | 'email'>('last_name')
const sortedGuests = computed(() => {
    const arr = [...guestsRaw.value]
    switch (sortBy.value) {
        case 'last_name':
            return arr.sort((a, b) => a.last_name.localeCompare(b.last_name))
        case 'guest_number':
            return arr.sort((a, b) => a.guest_number - b.guest_number)
        case 'email':
            return arr.sort((a, b) => a.email.localeCompare(b.email))
        default:
            return arr
    }
})

// Load the default paginated list
async function loadGuests(url = '/guests') {
    loading.value = true
    try {
        const response = await api.get(url)
        const rawData: GuestResponse[] = response.data.data

        guestsRaw.value = rawData.map((item) => ({
            id: item.id,
            guest_number: item.attributes.guestNumber,
            first_name: item.attributes.firstName,
            last_name: item.attributes.lastName,
            email: item.attributes.email,
            phone: item.attributes.phone
        }))

        meta.value = response.data.meta
        currentUrl.value = url

        inSearchMode.value = false
    } catch (err) {
        console.error('Failed to load guests:', err)
    } finally {
        loading.value = false
    }
}

// Called on mount to load the first page
onMounted(() => {
    loadGuests()
})

// Called by pagination links
function goToPageFromUrl(url: string) {
    if (!url) return
    loadGuests(url)
}

// Clear the search => revert to default
function clearSearch() {
    searchTerm.value = ''
    loadGuests()
}

// Debounced search
function debounceSearch() {
    if (debounceTimer) clearTimeout(debounceTimer)

    // If user typed nothing => revert to default list
    if (!searchTerm.value) {
        guestsRaw.value = []
        loadGuests()
        return
    }

    debounceTimer = window.setTimeout(() => {
        doSearch(searchTerm.value.trim())
    }, 300)
}

// Actual search call
async function doSearch(q: string) {
    if (!q) {
        // just in case
        clearSearch()
        return
    }
    loading.value = true
    try {
        // Suppose /guests/search returns { status:'success', guests: [...] }
        const response = await api.get('/guests/autocomplete', { params: { guest: q } })
        const arr = response.data.guests || []
        // Map them to our shape
        guestsRaw.value = arr.map((g: any) => ({
            id: g.id,
            guest_number: g.guest_number,
            first_name: g.first_name,
            last_name: g.last_name,
            email: g.email,
            phone: g.phone
        }))
        // In search mode => hide pagination
        meta.value = null
        inSearchMode.value = true
    } catch (err) {
        console.error('Error searching guests:', err)
    } finally {
        loading.value = false
    }
}
</script>

<style scoped>
/* Minimal local styling; rely on Tailwind. */
</style>
