<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div class="bg-white rounded-lg w-full max-w-md p-6">
            <h2 class="text-xl font-semibold mb-4">Create Listing</h2>

            <!-- Error display -->
            <div v-if="error" class="bg-red-100 text-red-700 border border-red-200 p-2 mb-4">
                {{ error }}
            </div>

            <form @submit.prevent="createListing" class="space-y-4">
                <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">Type</label>
                    <select v-model="type"
                        class="border border-gray-300 rounded p-2 w-full focus:ring focus:ring-blue-200">
                        <option disabled value="">Select type</option>
                        <option value="single_room">Single Room</option>
                        <option value="family_room">Family Room</option>
                        <option value="queen_room">Queen Room</option>
                        <option value="king_room">King Room</option>
                        <option value="suite">Suite</option>
                        <option value="apartment">Apartment/Unit/Villa</option>
                        <option value="cabin">Cabin</option>
                        <option value="share_room">Share Room/Hostel</option>
                        <option value="house">House</option>
                        <option value="camp_site">Camp Site</option>
                        <option value="caravan">Caravan</option>
                        <option value="bed_breakfast">Bed and Breakfast</option>
                    </select>
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">Name</label>
                    <input type="text" v-model="name" class="border border-gray-300 rounded p-2 w-full" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">Listing Number</label>
                    <input type="text" v-model="listingNumber" class="border border-gray-300 rounded p-2 w-full"
                        placeholder="Room 101" />
                </div>

                <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">Description</label>
                    <textarea v-model="description" rows="2" class="border border-gray-300 rounded p-2 w-full" />
                </div>

                <!-- Beds, guests, etc. -->
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">Beds</label>
                        <input type="number" v-model="beds" class="border border-gray-300 rounded p-2 w-full" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">Max Guests</label>
                        <input type="number" v-model="maximumGuests"
                            class="border border-gray-300 rounded p-2 w-full" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">Max Adults</label>
                        <input type="number" v-model="maximumAdults"
                            class="border border-gray-300 rounded p-2 w-full" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">Max Children</label>
                        <input type="number" v-model="maximumChildren"
                            class="border border-gray-300 rounded p-2 w-full" />
                    </div>
                </div>

                <!-- Buttons -->
                <div class="flex justify-end space-x-3 mt-4">
                    <button type="button" @click="$emit('close')"
                        class="px-4 py-2 border border-gray-300 rounded text-gray-700">
                        Cancel
                    </button>
                    <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                        Create
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { api } from '@/lib/api'

interface Props {
    propertyId: number | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['close', 'created'])

const error = ref('')
const type = ref('')
const name = ref('')
const listingNumber = ref('')
const description = ref('')
const beds = ref<number>(1)
const maximumGuests = ref<number>(2)
const maximumAdults = ref<number>(2)
const maximumChildren = ref<number>(0)

async function createListing() {
    if (!props.propertyId) {
        error.value = 'No property selected.'
        return
    }
    error.value = ''

    try {
        // Example POST /listings
        // shape: { property_id, type, name, listingNumber, description, beds, etc. }
        const payload = {
            property_id: props.propertyId,
            type: type.value,
            name: name.value,
            identifier: listingNumber.value,
            description: description.value,
            beds: beds.value,
            max_guests: maximumGuests.value,
            max_adults: maximumAdults.value,
            max_children: maximumChildren.value
        }
        await api.post('/listings', payload)
        emit('created')
    } catch (err: any) {
        console.error('Create listing error:', err)
        error.value = err?.response?.data?.message || 'Failed to create listing.'
    }
}
</script>
