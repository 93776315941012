<template>
  <footer class="bg-gray-50">
    <!-- Container with a thin top border -->
    <div class="max-w-7xl mx-auto px-4 py-8 border-t border-gray-300">
      <!-- Responsive grid:
           1 column on mobile,
           2 columns on small screens,
           3 columns on md+ screens -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <!-- Column 1: Company Information -->
        <div>
          <h3 class="text-lg font-semibold text-gray-700">ozreservations.com</h3>
          <p class="mt-2 text-gray-600">
            We remove the middlemen taking profits offshore and give you direct access to the best accommodation in Australia.
          </p>
        </div>

        <!-- Column 2: Quick Links -->
        <div>
          <h3 class="text-lg font-semibold text-gray-700">Quick Links</h3>
          <ul class="mt-2 space-y-2">
            <li>
              <a href="/" class="text-gray-600 hover:text-gray-800">Home</a>
            </li>
            <li>
              <a href="/about" class="text-gray-600 hover:text-gray-800">About Us</a>
            </li>
            <li>
              <a href="/contact" class="text-gray-600 hover:text-gray-800">Contact</a>
            </li>
            <li>
              <a href="/blog" class="text-gray-600 hover:text-gray-800">Blog</a>
            </li>
          </ul>
        </div>

        <!-- Column 3: Other -->
        <div>
          <h3 class="text-lg font-semibold text-gray-700">Information</h3>
          <ul class="mt-2 space-y-2">
            <li>
              <a href="#" class="text-gray-600 hover:text-gray-800">Privacy Policy</a>
            </li>
            <li>
              <a href="#" class="text-gray-600 hover:text-gray-800">About Bookings</a>
            </li>
            <li>
                <p class="text-gray-600 hover:text-gray-800">Managing Director: donna@ozreservations.com</p>
                <p  class="text-gray-600 hover:text-gray-800"> 0421 204 769</p>
            </li>
            <li>
                <p href="#" class="text-gray-600 hover:text-gray-800">CTO: james@ozreservations.com</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- Copyright / Bottom line -->
      <div class="mt-8 border-t border-gray-200 pt-4">
        <p class="text-center text-gray-500 text-sm">
          &copy; {{ currentYear }} OzReservations. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue'
const currentYear = new Date().getFullYear()
</script>

<style scoped>
/* You can add extra custom styles if needed */
</style>
