// src/components/RevenueChart.vue
<script setup lang="ts">
import { Line } from 'vue-chartjs'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'

// Register ChartJS components we need
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

// Define props for the component
interface Props {
    chartData: {
        labels: string[]
        datasets: {
            label: string
            data: number[]
            borderColor: string
            backgroundColor: string
            tension: number
        }[]
    }
}

const props = defineProps<Props>()

// Chart options for a clean, modern look
const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            grid: {
                color: 'rgba(0, 0, 0, 0.1)'
            }
        },
        x: {
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            position: 'bottom' as const
        }
    }
}
</script>

<template>
    <Line :data="chartData" :options="chartOptions" class="h-full" />
</template>
