<!-- src/views/DashboardView.vue -->
<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { api } from '@/lib/api'
import BookingModal from '@/components/NewBookingModal.vue'
import EditBookingModal from './EditBookingModal.vue'
import DashboardNav from './DashboardNav.vue'
import { useAuthStore } from '@/stores/auth'
import { DateTime } from 'luxon'
import { Room, Booking } from '@/types'

const auth = useAuthStore()
const rooms = ref<Room[]>([])
const bookings = ref<Booking[]>([])
const showBookingModal = ref(false)
const selectedDate = ref<Date | null>(null)
const selectedRoom = ref<Room | null>(null)
const loading = ref(true)
const showEditModal = ref(false)
const selectedBooking = ref<Booking | null>(null)
// Replace currentMonth with currentViewDate
const currentViewDate = ref(new Date()) // Initialize to today, but will change with navigation

// Add new state for info card
const showInfoCard = ref(false)
const infoCardBooking = ref<Booking | null>(null)
const infoCardTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

function showGuestName(booking: Booking | null) {
    if (!booking || !booking.guest) return ''
    const { firstName, lastName } = booking.guest

    // If you want LASTNAME, First:
    return `${lastName.toUpperCase()}, ${firstName}`
}

// Remove old tooltip/hover functions and add these instead:
function showBookingInfo(booking: Booking) {

    infoCardBooking.value = booking
    showInfoCard.value = true

    // Clear any existing timeout
    if (infoCardTimeout.value) {
        clearTimeout(infoCardTimeout.value)
    }

    // Set new timeout
    infoCardTimeout.value = setTimeout(() => {
        hideBookingInfo()
    }, 10000) // 10 seconds
}

function hideBookingInfo() {
    showInfoCard.value = false
    infoCardBooking.value = null
    if (infoCardTimeout.value) {
        clearTimeout(infoCardTimeout.value)
    }
}

function isCheckInDay(booking: Booking, date: Date) {

    // console.log('Checking date:', date)
    // console.log('Booking check-in:', booking.checkIn)

    // Convert both to “start of day” to compare ignoring time:
    const checkIn = new Date(booking.checkIn) // e.g. "2025-01-17"
    const bookingCheckInDay = new Date(
        checkIn.getFullYear(),
        checkIn.getMonth(),
        checkIn.getDate()
    )

    const targetDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    )

    // Return true if targetDay === the check-in day
    return targetDay.getTime() === bookingCheckInDay.getTime()
}


// Update weeks computed
const weeks = computed(() => {
    const startOfWeek = new Date(currentViewDate.value)
    // Adjust to start of week (Sunday)
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay())
    return generateCalendarDays(startOfWeek)
})

function generateCalendarDays(startDate: Date) {
    const days = []
    const current = new Date(startDate)
    const week = []

    // Generate just one week
    for (let i = 0; i < 7; i++) {
        week.push(new Date(current))
        current.setDate(current.getDate() + 1)
    }
    days.push(week)

    return days
}

function previousWeek() {
    currentViewDate.value = new Date(currentViewDate.value.setDate(currentViewDate.value.getDate() - 7))
    loadBookings()
}

function nextWeek() {
    currentViewDate.value = new Date(currentViewDate.value.setDate(currentViewDate.value.getDate() + 7))
    loadBookings()
}

function handleBookingUpdated() {
    // Reload bookings
    loadBookings()

    // Clear any info card showing old data
    hideBookingInfo()

    // Close the edit modal
    showEditModal.value = false
    selectedBooking.value = null
}


function getBookingForDate(room: Room, date: Date): Booking | null {
    // Normalize date to start of day
    const targetDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())

    return bookings.value.find((booking) => {
        // 1) Does this booking include the "room" listing?
        const hasListing = booking.listings.some((listing) => listing.id === room.id)

        // 2) Is the targetDate within [checkIn, checkOut)?
        const checkIn = new Date(booking.checkIn)
        const checkOut = new Date(booking.checkOut)
        const isWithinDates =
            targetDate >= new Date(checkIn.getFullYear(), checkIn.getMonth(), checkIn.getDate()) &&
            targetDate < new Date(checkOut.getFullYear(), checkOut.getMonth(), checkOut.getDate())

        return hasListing && isWithinDates
    }) ?? null
}

async function loadBookings() {
    try {
        const weekStart = weeks.value[0][0]
        const weekEnd = weeks.value[0][6]
        const response = await api.get(`/properties/${auth.selectedProperty}/bookings`, {
            params: {
                start_date: DateTime.fromJSDate(weekStart).toFormat('yyyy-LL-dd'),
                end_date: DateTime.fromJSDate(weekEnd).toFormat('yyyy-LL-dd')
            }
        })

        // The actual bookings array is in response.data.data
        const rawBookings = response.data.data || []

        bookings.value = rawBookings.map((raw: any) => {
            // Basic
            const bookingId = Number(raw.id)
            const { bookingNumber, checkIn, checkOut, totalFee, status, type, reason, adults, children, infants } = raw.attributes

            // relationships -> listings
            let listingArray = []
            if (raw.relationships?.listings && Array.isArray(raw.relationships.listings)) {
                listingArray = raw.relationships.listings.map((lst: any) => ({
                    id: Number(lst.id),
                    ...lst.attributes,
                }))
            }

            // relationships -> guest
            let guestObj = null
            if (raw.relationships?.guest) {
                const g = raw.relationships.guest
                guestObj = {
                    id: Number(g.id),
                    ...g.attributes,
                }
            }

            let booking = {
                id: bookingId,
                bookingNumber,
                checkIn,           // e.g. "2025-01-17"
                checkOut,          // e.g. "2025-01-23"
                totalFee,          // e.g. "12350"
                status,
                type,
                reason,
                adults,
                children,
                infants,
                listings: listingArray,  // array of listings
                guest: guestObj,         // single guest object
            }

            return booking
        })
    } catch (error) {
        console.error('Error loading bookings:', error)
    }
}


function handleDateClick(date: Date, room: Room) {
    // console.info('Clicked date:', date);

    const existingBooking = getBookingForDate(room, date)
    if (existingBooking) {
        selectedBooking.value = existingBooking
        showEditModal.value = true
    } else {
        // Ensure the date is set to start of day in local timezone
        selectedDate.value = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0, 0, 0
        )
        selectedRoom.value = room
        showBookingModal.value = true
    }
}

async function loadRooms() {
    try {
        const response = await api.get('/properties/' + auth.selectedProperty)
        rooms.value = response.data.data.relationships?.listings?.map((room: Room) => ({
            id: room.id,
            ...room.attributes,
        }))
        await loadBookings()
    } catch (error) {
        console.error('Error loading rooms:', error)
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    loadRooms()
})

watch(
    () => auth.selectedProperty,
    async (newPropId, oldPropId) => {
        if (newPropId && newPropId !== oldPropId) {
            // Re-fetch data for the newly selected property
            await loadRooms()
            await loadBookings()
        }
    }
)
</script>

<template>
    <div class="min-h-screen bg-indigo-500">
        <DashboardNav />
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div class="space-y-8">
                <!-- Calendar Header -->
                <div class="flex justify-between items-center">
                    <span>
                        <h1 class="text-2xl font-semibold text-white">{{ auth.propertyInformation?.name }}</h1>
                        <h2 class="text-2xl font-semibold text-white">Booking Calendar</h2>
                    </span>
                    <div class="flex space-x-4 items-center">
                        <button @click="previousWeek"
                            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                            Previous Week
                        </button>
                        <span class="text-lg text-gray-100 font-medium">
                            <template
                                v-if="DateTime.fromJSDate(weeks[0][0]).month !== DateTime.fromJSDate(weeks[0][6]).month">
                                {{ DateTime.fromJSDate(weeks[0][0]).toFormat('LLLL') }} -
                                {{ DateTime.fromJSDate(weeks[0][6]).toFormat('LLLL yyyy') }}
                            </template>
                            <template v-else>
                                {{ DateTime.fromJSDate(weeks[0][0]).toFormat('LLLL yyyy') }}
                            </template>
                        </span>
                        <button @click="nextWeek"
                            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                            Next Week
                        </button>
                    </div>
                </div>

                <!-- Loading State -->
                <div v-if="loading" class="flex justify-center py-12">
                    <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                </div>

                <!-- Calendar Grid -->
                <div v-else class="bg-white shadow rounded-lg overflow-hidden">
                    <div class="grid grid-cols-8 gap-px bg-gray-200">
                        <!-- Column headers -->
                        <div class="bg-gray-50 p-2">
                            <span class="text-xs font-medium text-gray-700">Room</span>
                        </div>
                        <div v-for="(date, index) in weeks[0]" :key="index" class="bg-gray-50 p-2">
                            <span class="text-xs font-medium text-gray-700">
                                {{ DateTime.fromJSDate(date).toFormat('ccc d') }}
                            </span>
                        </div>

                        <!-- Room rows -->
                        <template v-for="room in rooms" :key="room.id">
                            <!-- Room number -->
                            <div :class="['bg-white p-2 border-t', { 'opacity-50': !room.is_active }]">
                                <span class="text-sm font-medium text-gray-900">{{ room.listingNumber }}</span>
                            </div>

                            <!-- In the Days template -->
                            <template v-for="date in weeks[0]" :key="date.toLocaleDateString()">
                                <div :class="[
                                    'relative bg-white p-2 border-t cursor-pointer hover:bg-indigo-100']"
                                    @click="handleDateClick(date, room)">
                                    <!-- <div 
                                    :class="[
                                        'relative bg-white p-2 border-t',
                                        room.is_active ? 'cursor-pointer hover:bg-gray-50' : 'cursor-not-allowed opacity-50'
                                    ]"
                                    @click="room.is_active ? handleDateClick(date, room) : null"
                                > -->

                                    <!-- Booking indicator with info icon -->
                                    <template v-if="getBookingForDate(room, date) as foundBooking">
                                        <!-- Possibly show name only if it's the first day of that booking -->
                                        <span v-if="isCheckInDay(getBookingForDate(room, date), date)"
                                            class="text-xs text-gray-500">
                                            {{ showGuestName(getBookingForDate(room, date)) }}
                                        </span>

                                        <!-- Info icon always visible if there's a booking -->
                                        <button class="absolute top-0 right-0 p-1"
                                            @click.stop="showBookingInfo(getBookingForDate(room, date))">
                                            <svg class="w-4 h-4 text-yellow-500 hover:text-yellow-600" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 
           12a9 9 0 11-18 0 9 9 0 0118 
           0z" />
                                            </svg>
                                        </button>

                                        <!-- Booking indicator bar still drawn for every day of the range -->
                                        <div class="absolute bottom-0 left-0 right-0 h-1.5 bg-blue-500"></div>
                                    </template>

                                </div>
                            </template>

                        </template>
                    </div>
                </div>

                <!-- Booking details tooltip -->
                <!-- Replace the old tooltip with new info card -->
                <Teleport to="body">
                    <Transition enter-active-class="transition ease-out duration-200"
                        enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 scale-100"
                        leave-to-class="opacity-0 scale-95">
                        <div v-if="showInfoCard && infoCardBooking"
                            class="fixed right-4 top-20 z-50 bg-white p-4 rounded-lg shadow-lg border border-gray-200 max-w-sm">
                            <div class="flex justify-between items-start mb-3">
                                <h4 class="font-medium">Booking Details</h4>
                                <button @click="hideBookingInfo" class="text-gray-400 hover:text-gray-500">
                                    <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            <div class="space-y-2">
                                <p class="font-medium">
                                    Booking #{{ infoCardBooking.bookingNumber }}
                                </p>

                                <p v-if="infoCardBooking.guest" class="text-sm">
                                    <strong>Guest: </strong>
                                    {{ infoCardBooking.guest.lastName.toUpperCase() }},
                                    {{ infoCardBooking.guest.firstName }}
                                </p>

                                <p v-if="infoCardBooking.guest" class="text-sm text-gray-600">
                                    Phone: {{ infoCardBooking.guest.phone }}<br />
                                    Email: {{ infoCardBooking.guest.email }}
                                </p>

                                <p class="text-sm text-gray-600">
                                    Check-in: {{ new Date(infoCardBooking.checkIn).toLocaleDateString() }}
                                </p>
                                <p class="text-sm text-gray-600">
                                    Check-out: {{ new Date(infoCardBooking.checkOut).toLocaleDateString() }}
                                </p>

                                <p v-if="infoCardBooking.type" class="text-sm text-gray-600">
                                    Booking Type: {{ infoCardBooking.type }}
                                </p>

                                <p v-if="infoCardBooking.type !== 'guest'" class="text-sm text-gray-600">
                                    System Booking Reason: {{ infoCardBooking.reason }}
                                </p>

                                <!-- If your API eventually returns "adults", "children", etc. -->
                                <p v-if="infoCardBooking.adults != null" class="text-sm">Adults: {{
                                    infoCardBooking.adults }}</p>
                                <p v-if="infoCardBooking.children != null" class="text-sm">Children: {{
                                    infoCardBooking.children }}</p>
                                <p v-if="infoCardBooking.infants != null" class="text-sm">Infants: {{
                                    infoCardBooking.infants }}</p>

                                <!-- If you add payment fields later, show them conditionally here -->

                                <p class="text-sm font-medium">
                                    Total Fee: ${{ parseFloat(infoCardBooking.totalFee / 100).toFixed(2) }}
                                </p>
                            </div>
                        </div>
                    </Transition>
                </Teleport>
            </div>
        </div>

        <!-- Edit Booking Modal -->
        <EditBookingModal v-if="showEditModal && selectedBooking" v-model="showEditModal" :booking="selectedBooking!"
            @booking-updated="handleBookingUpdated" />

        <!-- New Booking Modal -->
        <BookingModal v-if="showBookingModal" v-model="showBookingModal" :date="selectedDate!" :room="selectedRoom!"
            @booked="loadBookings" />
    </div>
</template>
