<!-- src/components/BookingModal.vue -->

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { defineProps, defineEmits } from 'vue'
import { DateTime } from 'luxon'
import { api } from '@/lib/api'
import { useAuthStore } from '@/stores/auth'

// Stripe
import { loadStripe, Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js'
import type { PaymentIntent } from '@stripe/stripe-js'

import type { Room } from '@/types'

// Props
interface Props {
    modelValue: boolean
    date: Date
    room: Room
}
const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue', 'booked'])

// Auth store
const auth = useAuthStore()

// Stripe setup
const publishableKey = import.meta.env.VITE_STRIPE_KEY
if (!publishableKey) {
    throw new Error('Stripe publishable key not found')
}

const stripePromise = loadStripe(publishableKey)
const stripe = ref<Stripe | null>(null)
const elements = ref<StripeElements | null>(null)
const cardElement = ref<StripeCardElement | null>(null)

const cardElementRef = ref<HTMLDivElement | null>(null)
const cardMounted = ref(false)

const firstName = ref('')
const lastName = ref('')
const email = ref('')
const phone = ref('')
const nights = ref(1)
const adults = ref(1)
const children = ref(0)
const infants = ref(0)

const loading = ref(false)
const error = ref('')
const stripePaymentError = ref('')
const processingPayment = ref(false)
const wasBookingError = ref(false)

// We store the “fees” returned by the server (in cents)
const fees = ref<any>({
    total_fee: 0,
    daily_breakdown: [],
    booking_fee: 0,
    commission: 0,
    tax: 0,
    deposit_required: false,
    deposit_amount: 0,
    balance: 0,
    grand_total: 0,
})

// Rooms logic for multiple selection
const showRoomSelection = ref(false)
const loadingRooms = ref(false)
const availableRooms = ref<Room[]>([])
const selectedRooms = ref<number[]>([])

// By default, select the single room the user clicked in the calendar
onMounted(() => {
    selectedRooms.value = props.room?.id ? [props.room.id] : []
})

// Calculate check-in, check-out
const checkInDate = computed(() => {
    return DateTime.fromJSDate(props.date).toISODate()
})
const checkOutDate = computed(() => {
    return DateTime.fromJSDate(props.date)
        .plus({ days: nights.value })
        .toISODate()
})

// Search for available rooms whenever checkIn, checkOut change
async function loadAvailableRooms() {
    if (!checkInDate.value || !checkOutDate.value) return
    loadingRooms.value = true
    try {
        const response = await api.post('/listings/available', {
            propertyId: auth.selectedProperty,
            checkInDate: checkInDate.value,
            checkOutDate: checkOutDate.value,
        })
        // Map the returned data into local shape
        availableRooms.value = response.data.listings?.map((r: any) => ({
            ...r,
        })) || []
    } catch (err) {
        console.error('Error loading available rooms:', err)
    } finally {
        loadingRooms.value = false
    }
}

// Re-calc fees whenever date range or selected rooms changes
async function calculateFees() {
    try {
        if (!checkInDate.value || !checkOutDate.value || !selectedRooms.value.length) {
            return
        }
        // Example: we do a POST to /bookings/calculate with property_id, check_in, check_out, and listing IDs
        const response = await api.post('/bookings/calculate', {
            property_id: auth.selectedProperty,
            check_in: checkInDate.value,
            check_out: checkOutDate.value,
            listing_ids: selectedRooms.value,
        })
        if (response.data?.fees) {
            fees.value = response.data.fees
        }
    } catch (e: any) {
        console.error('Failed to calculate fees:', e)
        error.value = e?.response?.data?.message || 'Failed to calculate fees: is there a listing fee for this type of listing?'
    }
}

// Watch checkIn/checkOut to reload rooms and re-calc fees
watch([checkInDate, checkOutDate], () => {
    loadAvailableRooms()
    calculateFees()
}, { immediate: true })

// Also watch selectedRooms for fee recalculation
watch(() => selectedRooms.value, () => {
    calculateFees()
})

// Stripe: mount card element on mount
onMounted(async () => {
    try {
        stripe.value = await stripePromise
    } catch (e) {
        console.error('Failed to load Stripe:', e)
    }

    // As soon as the modal is open, mount the card element
    // (or you could wait until something else)
    watch(cardElementRef, async (el) => {
        if (el && !cardMounted.value && stripe.value) {
            elements.value = stripe.value.elements()
            cardElement.value = elements.value.create('card')
            cardElement.value.mount(el)
            cardMounted.value = true
        }
    }, { immediate: true })
})

// handleSubmit => create booking, then process Stripe
async function handleSubmit() {
    loading.value = true
    error.value = ''
    stripePaymentError.value = ''

    // Basic checks
    if (!firstName.value || !lastName.value) {
        error.value = 'Please enter first and last name.'
        loading.value = false
        return
    }
    if (!selectedRooms.value.length) {
        error.value = 'Please select at least one room.'
        loading.value = false
        return
    }

    try {
        // 1) Create the booking on the server
        const bookingResponse = await api.post('/bookings', {
            guest: {
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
                phone: phone.value,
            },
            property_id: auth.selectedProperty,
            check_in: checkInDate.value,
            check_out: checkOutDate.value,
            listing_ids: selectedRooms.value,
            adults: adults.value,
            children: children.value
        })

        const bookingData = bookingResponse.data

        if (bookingData.status === 'error') {
            wasBookingError.value = true
            console.error(`Unable to create booking: ${bookingData.status}: ${bookingData.message}`)
            throw new Error(bookingData.message || 'Unable to create booking, check availability')
        }

        console.log('Booking created successfully:', bookingData)
        wasBookingError.value = false

        // 2) Process Stripe payment
        processingPayment.value = true
        try {
            if (!stripe.value || !cardElement.value) {
                throw new Error('Stripe not initialized.')
            }

            console.log('Creating payment intent for booking_id:', bookingData.booking_id)

            // Create payment intent
            const intentResponse = await api.post('/payments/create-payment-intent', {
                amount: bookingData.fees.deposit_required ? fees.value.deposit_amount : fees.value.grand_total,
                booking_number: bookingData.booking_number,
                booking_id: bookingData.booking_id,
                description: `Booking #${bookingData.booking_number}`,
                payment_type: fees.value.deposit_required ? 'deposit' : 'full',
                email: email.value,
            })
            const { client_secret } = intentResponse.data
            if (!client_secret) {
                throw new Error('No client secret received.')
            }

            // Confirm payment
            const result = await stripe.value.confirmCardPayment(client_secret, {
                payment_method: {
                    card: cardElement.value,
                    billing_details: {
                        name: `${firstName.value} ${lastName.value}`,
                        email: email.value,
                    },
                },
            })

            if (result.error) {
                throw new Error(result.error.message || 'Payment failed')
            }
            if (!result.paymentIntent) {
                throw new Error('No payment intent returned by Stripe.')
            }

            console.log('Payment processed successfully:', result.paymentIntent)

            // Payment success => close modal
            emit('booked')
            emit('update:modelValue', false)
        } catch (stripeErr: any) {

            if (wasBookingError.value) {
                error.value = stripeErr instanceof Error
                    ? stripeErr.message
                    : 'Unable to create booking, please check availability'
            }
            else {

                console.error('Stripe payment error:', stripeErr)
                stripePaymentError.value = stripeErr instanceof Error
                    ? stripeErr.message
                    : 'Payment error.'
                
                    error.value = stripePaymentError.value

                // (Optional) Cancel the booking on server if payment fails
                try {
                    await api.delete(`/bookings/${bookingData.booking_id}`)
                } catch (cancelErr) {
                    console.warn('Failed to cancel booking after stripe error:', cancelErr)
                }

            }
            
        } finally {
            processingPayment.value = false
        }
    } catch (bookingErr: any) {
        console.error('Booking creation error:', bookingErr)
        if (bookingErr.response?.data?.message) {
            error.value = bookingErr.response.data.message
        } else if (bookingErr.message) {
            error.value = bookingErr.message
        } else {
            error.value = 'Failed to create booking'
        }
    } finally {
        loading.value = false
    }
}

// Autocomplete guests
const searchResults = ref<any[]>([])
const showSearchResults = ref(false)
const searchTimeout = ref<number | null>(null)

async function debouncedGuestSearch(query: string) {
    if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
    }
    if (query.length < 2) {
        showSearchResults.value = false
        return
    }
    searchTimeout.value = window.setTimeout(async () => {
        try {
            // e.g. /guests/autocomplete?guest= + query
            const res = await api.get('/guests/autocomplete', {
                params: { guest: query },
            })
            searchResults.value = res.data.guests || []
            showSearchResults.value = true
        } catch (err) {
            console.error('Guest search error:', err)
        }
    }, 300)
}

// Watch lastName for autocomplete
watch(() => lastName.value, (val) => {
    if (val) {
        debouncedGuestSearch(val)
    }
})

// Select from autocomplete => fill firstName, lastName, phone, email
function selectGuest(guest: any) {
    firstName.value = guest.first_name
    lastName.value = guest.last_name
    phone.value = guest.phone
    email.value = guest.email
    showSearchResults.value = false
}

// Close the modal
function close() {
    emit('update:modelValue', false)
}
</script>

<template>
    <!-- Modal backdrop -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
        <div class="bg-white rounded-lg w-full max-w-4xl p-6 overflow-y-auto max-h-screen" style="max-height: 90vh;">
            <!-- Modal Header -->
            <div class="flex justify-between items-center mb-4">
                <h3 class="text-lg font-medium text-gray-900">
                    New Booking -
                    <!-- If more than 1 room selected, show 'Multiple Rooms'. Otherwise the listingNumber -->
                    <span v-if="selectedRooms.length > 1">
                        Multiple Rooms ({{ selectedRooms.length }})
                    </span>
                    <span v-else>
                        {{ room?.listingNumber || 'Room' }}
                    </span>
                </h3>
                <button @click="close" class="text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Close</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <!-- Error Alert -->
            <div v-if="error" class="rounded-md bg-red-50 p-4 mb-4">
                <div class="text-sm text-red-700">{{ error }}</div>
            </div>

            <!-- Form -->
            <form @submit.prevent="handleSubmit" class="space-y-6">
                <!-- Dates and Nights -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label class="block text-sm font-medium text-gray-700">
                            Dates
                        </label>
                        <div class="mt-1 flex flex-col space-y-1">
                            <span class="text-sm text-gray-500">
                                Check-in:
                                {{ DateTime.fromISO(checkInDate).toFormat('dd/LL/yyyy') }}
                            </span>
                            <span class="text-sm text-gray-500">
                                Check-out:
                                {{ DateTime.fromISO(checkOutDate).toFormat('dd/LL/yyyy') }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Nights</label>
                        <input type="number" v-model="nights" min="1" class="mt-1 w-full rounded-md border-gray-300 shadow-sm
                     focus:border-indigo-500 focus:ring-indigo-500" />
                    </div>
                </div>

                <!-- NEW: Adults & Children row -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Adults</label>
                        <input type="number" v-model="adults" min="1" class="mt-1 w-full rounded-md border-gray-300 shadow-sm
                     focus:border-indigo-500 focus:ring-indigo-500" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Children</label>
                        <input type="number" v-model="children" min="0" class="mt-1 w-full rounded-md border-gray-300 shadow-sm
                     focus:border-indigo-500 focus:ring-indigo-500" />
                    </div>
                </div>

                <!-- Room Selection Accordion -->
                <div class="border rounded-md p-4">
                    <button type="button" class="flex items-center justify-between w-full"
                        @click="showRoomSelection = !showRoomSelection">
                        <span class="text-sm font-medium text-gray-700">
                            Additional Rooms
                        </span>
                        <svg :class="{ 'transform rotate-180': showRoomSelection }"
                            class="h-5 w-5 text-gray-500 transition-transform" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>

                    <!-- Collapsible area for available rooms -->
                    <transition name="fade">
                        <div v-if="showRoomSelection" class="mt-3">
                            <div v-if="loadingRooms" class="text-sm text-gray-500">
                                Loading available rooms...
                            </div>
                            <div v-else>
                                <template v-if="availableRooms.length > 0">
                                    <div v-for="r in availableRooms" :key="r.id"
                                        class="flex items-center space-x-2 mb-1">
                                        <input type="checkbox" :value="r.id" v-model="selectedRooms" />
                                        <span class="text-sm text-gray-700">
                                            {{ r.identifier || r.listingNumber }}
                                            ({{ r.name }})
                                        </span>
                                    </div>
                                </template>
                                <p v-else class="text-sm text-gray-500 italic">
                                    No rooms available for these dates.
                                </p>
                            </div>
                        </div>
                    </transition>
                </div>

                <!-- Guest Information -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <!-- Left Column -->
                    <div class="space-y-4">
                        <!-- First Name -->
                        <div class="relative">
                            <label class="block text-sm font-medium text-gray-700">
                                First Name
                            </label>
                            <input type="text" v-model="firstName" class="mt-1 w-full rounded-md border-gray-300 shadow-sm
                       focus:border-indigo-500 focus:ring-indigo-500" required />
                        </div>

                        <!-- Last Name (with autocomplete) -->
                        <div class="relative">
                            <label class="block text-sm font-medium text-gray-700">
                                Last Name (or guest #, email, phone) for auto‐search
                            </label>
                            <input type="text" v-model="lastName" class="mt-1 w-full rounded-md border-gray-300 shadow-sm
                       focus:border-indigo-500 focus:ring-indigo-500" required />

                            <!-- Autocomplete dropdown -->
                            <div v-if="showSearchResults && searchResults.length > 0" class="absolute z-10 mt-1 w-full bg-white shadow-lg
                       max-h-60 rounded-md py-1 text-base ring-1 ring-black
                       ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <div v-for="guest in searchResults" :key="guest.id"
                                    class="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
                                    @click="selectGuest(guest)">
                                    <div class="flex items-center">
                                        <span class="block truncate text-sm text-gray-700">
                                            {{ guest.last_name.toUpperCase() }}, {{ guest.first_name }}
                                            ({{ guest.guest_number }})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Right Column -->
                    <div class="space-y-4">
                        <!-- Email -->
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Email</label>
                            <input type="email" v-model="email" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                       focus:border-indigo-500 focus:ring-indigo-500" />
                        </div>

                        <!-- Phone -->
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Phone</label>
                            <input type="tel" v-model="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                       focus:border-indigo-500 focus:ring-indigo-500" />
                        </div>
                    </div>
                </div>

                <!-- Amount & Payment (always stripe now) -->
                <div>
                    <label class="block text-sm font-medium text-gray-700">Amount</label>
                    <div class="mt-1 text-lg font-medium">
                        ${{ (fees.grand_total / 100).toFixed(2) }}
                        <span v-if="fees.deposit_required" class="text-green-600 ml-2">
                            (Deposit required: ${{ (fees.deposit_amount / 100).toFixed(2) }})
                        </span>
                    </div>
                </div>

                <!-- Stripe Payment Element -->
                <div class="mt-4">
                    <label class="block text-sm font-medium text-gray-700">
                        Card Details
                    </label>
                    <div ref="cardElementRef" class="mt-1 p-3 border rounded-md shadow-sm"></div>
                    <div v-if="stripePaymentError" class="mt-2 text-sm text-red-600">
                        {{ stripePaymentError }}
                    </div>
                    <div v-if="processingPayment" class="mt-2 text-sm text-gray-600">
                        Processing payment...
                    </div>
                </div>

                <!-- Rate Breakdown (fees.daily_breakdown) -->
                <div v-if="fees.daily_breakdown && fees.daily_breakdown.length > 0">
                    <h4 class="text-sm font-medium text-gray-700 mt-4">
                        Rate Breakdown:
                    </h4>
                    <div class="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 text-sm"
                        style="max-height: 150px; overflow: auto;">
                        <div v-for="(item, i) in fees.daily_breakdown" :key="i" class="border p-2 rounded-md">
                            <div>
                                {{ DateTime.fromISO(item.date).toFormat('dd/LL/yyyy') }}
                            </div>
                            <div>
                                ${{ (item.rate / 100).toFixed(2) }}
                            </div>
                            <!-- Possibly highlight “special” if item.adjustments.seasonal.applied, etc. -->
                            <div v-if="item.adjustments.seasonal.applied" class="text-green-600 text-xs">
                                +Seasonal
                            </div>
                            <div v-if="item.adjustments.occupancy.applied" class="text-red-600 text-xs">
                                +Occupancy
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Other fee details (booking_fee, commission, tax, etc.) if you want them -->
                <div class="mt-3 text-sm space-y-1 text-gray-700">
                    <p v-if="fees.booking_fee">
                        Booking Fee: ${{ (fees.booking_fee / 100).toFixed(2) }}
                    </p>
                    <p v-if="fees.commission">
                        Commission: ${{ (fees.commission / 100).toFixed(2) }}
                    </p>
                    <p v-if="fees.tax">
                        Tax: ${{ (fees.tax / 100).toFixed(2) }}
                    </p>
                    <p v-if="fees.grand_total">
                        Grand Total: ${{ (fees.grand_total / 100).toFixed(2) }}
                    </p>
                </div>

                <!-- Action Buttons -->
                <div class="mt-6 flex justify-end space-x-3">
                    <button type="button" @click="close" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white
                   border border-gray-300 rounded-md hover:bg-gray-50">
                        Cancel
                    </button>
                    <button type="submit" :disabled="loading" class="px-4 py-2 text-sm font-medium text-white bg-indigo-600
                   border border-transparent rounded-md hover:bg-indigo-700
                   disabled:opacity-50">
                        {{ loading ? 'Creating booking...' : 'Create Booking' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all .3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
