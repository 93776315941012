<template>
  <!-- Use a one‑column grid on mobile and two columns on md+ -->
  <div class="grid grid-cols-1 md:grid-cols-[300px_1fr] gap-8 min-h-[calc(100vh-64px)]">
    <!-- Sidebar: On mobile, this will not be sticky -->
    <div class="md:sticky md:top-8 h-auto flex flex-col gap-6 p-4">
      <router-link to="/" class="block mx-auto text-center">
        <img class="w-[200px] h-auto mb-4 mx-auto" :src="logoUrl" alt="OzReservations Logo" />
      </router-link>

      <!-- Featured Properties Card -->
      <div class="bg-white shadow rounded-lg p-4">
        <div class="flex items-center gap-2 text-blue-600 text-lg mb-4">
          <font-awesome-icon :icon="faStar" />
          <span>Featured Properties</span>
        </div>
        <div class="flex flex-col gap-4">
          <div
            v-for="(property, index) in featuredProperties"
            :key="index"
            class="flex items-center gap-2 bg-gray-100 p-2 rounded"
          >
            <img
              :src="property.image"
              :alt="property.name"
              class="w-[60px] h-[60px] object-cover rounded"
            />
            <div class="text-sm">
              <h4 class="font-semibold">{{ property.name }}</h4>
              <p class="text-gray-600">{{ property.location }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Results Section -->
    <div class="p-4 flex flex-col gap-6">
      <!-- Header with sorting -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">
          {{ results.length }} Properties Found
        </h2>
        <div>
          <select
            v-model="sortBy"
            class="border border-gray-300 rounded-md p-2 text-sm focus:outline-none focus:ring focus:ring-blue-200"
          >
            <option v-for="opt in sortOptions" :key="opt.value" :value="opt.value">
              {{ opt.label }}
            </option>
          </select>
        </div>
      </div>

      <!-- Scrollable Results Container with hidden scrollbar -->
      <div
        ref="scrollContainer"
        class="hide-scrollbar overflow-y-auto h-[calc(100vh-120px)] space-y-4 pr-2"
      >
        <!-- Each property card -->
        <div
          v-for="(property, index) in sortedResults"
          :key="index"
          class="bg-white shadow rounded-lg hover:shadow-md transition p-4"
        >
          <!-- Property Header -->
          <div class="flex items-center justify-between mb-2">
            <span class="px-3 py-1 bg-blue-600 text-white text-sm rounded-full uppercase">
              {{ property.type }}
            </span>
            <span :class="['text-sm', property.listings_available < 5 ? 'text-red-500' : 'text-gray-600']">
              {{ property.available_listings[0].available_count }} listings available
            </span>
          </div>

          <!-- Property Content -->
          <div class="grid md:grid-cols-[300px_1fr] gap-4">
            <!-- Image -->
            <img
              src="https://placehold.co/300x200"
              alt="Property"
              class="w-full h-[200px] object-cover rounded"
            />
            <!-- Info Column -->
            <div class="flex flex-col gap-2">
              <!-- Title & Rating -->
              <div class="flex justify-between items-center">
                <h3 class="text-lg font-semibold">
                  {{ property.name }}
                </h3>
                <div class="flex">
                  <font-awesome-icon
                    v-for="n in property.rating"
                    :key="n"
                    :icon="faStar"
                    class="text-yellow-400"
                  />
                </div>
              </div>
              <p class="text-gray-600">
                {{ property.description }}
              </p>

              <!-- Amenities / Capacity -->
              <div class="flex justify-between items-center border-t border-b py-2 mt-2">
                <div class="flex gap-4">
                  <div class="text-center">
                    <div class="flex justify-center mb-1 text-blue-600">
                      <font-awesome-icon
                        v-for="n in property.available_listings[0]?.max_adults"
                        :key="n"
                        :icon="faUser"
                      />
                    </div>
                    <span class="text-sm">
                      {{ property.available_listings[0]?.max_adults }} adults
                    </span>
                  </div>
                  <div class="text-center">
                    <div class="flex justify-center mb-1 text-blue-600">
                      <font-awesome-icon
                        v-for="n in property.available_listings[0]?.max_children"
                        :key="n"
                        :icon="faUser"
                        class="scale-75"
                      />
                    </div>
                    <span class="text-sm">
                      {{ property.available_listings[0]?.max_children }} children
                    </span>
                  </div>
                  <div class="text-center">
                    <div class="flex justify-center mb-1 text-blue-600">
                      <font-awesome-icon
                        v-for="n in property.available_listings[0]?.beds"
                        :key="n"
                        :icon="faBed"
                      />
                    </div>
                    <span class="text-sm">
                      {{ property.available_listings[0]?.beds }} beds
                    </span>
                  </div>
                </div>
                <div class="flex gap-2 text-blue-600">
                  <font-awesome-icon
                    v-for="(feature, fIdx) in getPropertyFeatures(property)"
                    :key="fIdx"
                    :icon="feature.icon"
                    :title="feature.label"
                  />
                </div>
              </div>

              <!-- Booking Section -->
              <div class="flex justify-between items-center mt-4">
                <div
                  class="relative flex flex-col cursor-pointer"
                  @click="togglePriceInfo(index)"
                >
                  <span class="text-2xl text-blue-600 font-bold">
                    ${{ property.available_listings[0]?.price?.grand_total }}
                  </span>
                  <span class="text-sm text-gray-500">
                    {{
                      calculateNights(property.available_listings[0]?.price?.daily_breakdown.length)
                    }}
                  </span>
                  <font-awesome-icon :icon="faInfoCircle" class="text-gray-400 mt-1" />

                  <!-- Price Breakdown Popover -->
                  <div
                    v-if="showDailyRates[index]"
                    class="absolute top-full mt-2 w-[300px] bg-white border border-gray-200 rounded shadow p-3 z-10"
                  >
                    <div class="text-sm flex flex-col gap-2">
                      <div class="flex justify-between border-b pb-1">
                        <span>Subtotal</span>
                        <span>
                          ${{ property.available_listings[0]?.price?.total_fee }}
                        </span>
                      </div>
                      <div class="flex justify-between border-b pb-1">
                        <span>Tax (GST)</span>
                        <span>
                          ${{ property.available_listings[0]?.price?.tax }}
                        </span>
                      </div>
                      <div class="flex justify-between border-b pb-1">
                        <span>Fees</span>
                        <span>{{ calculateFees(property.available_listings[0]?.price) }}</span>
                      </div>
                      <div class="flex justify-between border-t pt-2 font-semibold">
                        <span>Total</span>
                        <span>
                          ${{ property.available_listings[0]?.price?.grand_total }}
                        </span>
                      </div>
                      <div
                        v-if="property.available_listings[0]?.price?.deposit_required"
                        class="bg-yellow-100 p-2 mt-2 text-center rounded font-medium"
                      >
                        Deposit Required: ${{ property.available_listings[0]?.price?.deposit_amount }}
                      </div>
                      <button
                        @click.stop="toggleDailyDetail(index)"
                        class="mt-2 flex items-center justify-between w-full text-blue-600"
                      >
                        <span>Daily Rate Breakdown</span>
                        <font-awesome-icon
                          :icon="faChevronDown"
                          :class="showDetailedDaily[index] ? 'rotate-180' : ''"
                          class="transition-transform"
                        />
                      </button>
                      <div
                        v-if="showDetailedDaily[index]"
                        class="mt-2 max-h-44 overflow-y-auto border-t pt-2"
                      >
                        <div
                          v-for="(day, dayIndex) in property.available_listings[0]?.price?.daily_breakdown"
                          :key="dayIndex"
                          class="border-b pb-2 mb-2"
                        >
                          <div class="font-semibold">
                            {{ formatDate(day.date) }}
                          </div>
                          <div class="pl-2 text-sm text-gray-600">
                            Base Rate: ${{ day.adjustments.base_rate }}<br />
                            <span v-if="day.adjustments.seasonal.applied">
                              + Seasonal ({{ day.adjustments.seasonal.type }}):
                              ${{ day.adjustments.seasonal.amount }}
                            </span><br />
                            <span v-if="day.adjustments.occupancy.applied">
                              + Occupancy: ${{ day.adjustments.occupancy.amount }}
                            </span><br />
                            <strong class="text-black">
                              Daily Total: ${{ day.rate }}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition">
                  <font-awesome-icon :icon="faCalendar" class="mr-2" />
                  Reserve
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- End of a property card -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faStar,
  faUser,
  faBed,
  faInfoCircle,
  faChevronDown,
  faCalendar
} from '@fortawesome/free-solid-svg-icons'
import logoUrl from '@/assets/ozreservations.png'
import beachResort from '@/assets/beach-resort.jpeg'
import apartments from '@/assets/apartments.jpeg'
import api from '@/lib/publicapi'

const route = useRoute()

// Data for results, sorting, and featured properties
const results = ref([])
const sortBy = ref('price_asc')
const sortOptions = [
  { label: 'Price: Low to High', value: 'price_asc' },
  { label: 'Price: High to Low', value: 'price_desc' },
  { label: 'Rating', value: 'rating' },
  { label: 'Most Available', value: 'availability' }
]

const featuredProperties = ref([
  {
    name: 'Luxury Beach Resort',
    location: 'Gold Coast, QLD',
    image: beachResort
  },
  {
    name: 'City Center Apartments',
    location: 'Sydney, NSW',
    image: apartments
  }
])

// For price popover and daily breakdown toggles
const showDailyRates = ref({})
const showDetailedDaily = ref({})

function togglePriceInfo(index) {
  showDailyRates.value[index] = !showDailyRates.value[index]
}
function toggleDailyDetail(index) {
  showDetailedDaily.value[index] = !showDetailedDaily.value[index]
}

// Infinite scroll container and page management
const scrollContainer = ref(null)
const isLoading = ref(false)
let page = ref(1)

const sortedResults = computed(() => {
  const sorted = [...results.value]
  switch (sortBy.value) {
    case 'price_asc':
      return sorted.sort(
        (a, b) =>
          (a.available_listings[0]?.price?.total_fee ?? 0) -
          (b.available_listings[0]?.price?.total_fee ?? 0)
      )
    case 'price_desc':
      return sorted.sort(
        (a, b) =>
          (b.available_listings[0]?.price?.total_fee ?? 0) -
          (a.available_listings[0]?.price?.total_fee ?? 0)
      )
    case 'rating':
      return sorted.sort((a, b) => (b.rating ?? 0) - (a.rating ?? 0))
    case 'availability':
      return sorted.sort(
        (a, b) =>
          (b.available_listings?.length ?? 0) - (a.available_listings?.length ?? 0)
      )
    default:
      return sorted
  }
})

// Dummy feature mapping (customize as needed)
function getPropertyFeatures(property) {
  const featureMap = {
    wifi: { icon: faUser, label: 'Free WiFi' },
    pool: { icon: faUser, label: 'Pool' }
  }
  return []
}

function formatDate(dateStr) {
  const d = new Date(dateStr)
  return d.toLocaleDateString('en-AU', {
    weekday: 'short',
    day: 'numeric',
    month: 'short'
  })
}

function calculateFees(price) {
  if (!price) return '0.00'
  const sum = (parseFloat(price.booking_fee) || 0) + (parseFloat(price.commission) || 0)
  return sum.toFixed(2)
}

function calculateNights(n) {
  if (!n) return ''
  return `${n} ${n === 1 ? 'night' : 'nights'}`
}

onMounted(async () => {
  let response = await api.searchAccommodation(route.query)
  results.value = response.data.data
})

function handleScroll() {
  const container = scrollContainer.value
  if (!container || isLoading.value) return
  const { scrollTop, scrollHeight, clientHeight } = container
  if (scrollTop + clientHeight >= scrollHeight - 100) {
    loadMore()
  }
}

async function loadMore() {
  if (isLoading.value) return
  isLoading.value = true
  page.value++
  // Fetch next page of results...
  isLoading.value = false
}

onMounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener('scroll', handleScroll)
  }
})

onBeforeUnmount(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', handleScroll)
  }
})
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
