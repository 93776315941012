// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
// import HomePage from '@/components/NewHomePage.vue';
import LoginView from '@/components/LoginView.vue';
import RegisterView from '@/components/RegisterView.vue';
import DashboardView from '@/components/DashboardView.vue';
import ComingSoon from '@/components/ComingSoon.vue';
import PropertySettingsView from '@/components/PropertySettingsView.vue';
import RevenueView from '@/components/RevenueView.vue';
// import NotesView from '@/components/NotesView.vue';
import SearchHomePage from '@/components/SearchHomePage.vue';
import ListingsView from '@/components/ListingsView.vue';
import FeesAndRatesView from '@/components/FeesAndRatesView.vue';
import PropertySearch from '@/components/PropertySearch.vue';
import NewGuestsView from '@/components/NewGuestsView.vue';
import BookingCalendar from '@/components/BookingCalendar.vue';
import AboutView from '@/components/About.vue';
import ContactView from '@/components/Contact.vue';

const routes = [
    {
        path: '/',
        component: SearchHomePage
    },
    {
        path: '/search',
        name: 'SearchResults',
        component: PropertySearch
    },
    {
        path: '/login',
        component: LoginView,
        meta: { requiresGuest: true }
    },
    {
        path: '/manager-login',
        component: LoginView,
        meta: { requiresGuest: true }
    },
    {
        path: '/register',
        component: RegisterView,
        meta: { requiresGuest: true }
    },
    {
        path: '/dashboard',
        component: DashboardView,
        meta: { requiresAuth: true }
    },
    {
        path: '/bookings',
        component: BookingCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/guests',
        component: NewGuestsView,
        meta: { requiresAuth: true }
    },
    {
        path: '/listings',
        component: ListingsView,
        meta: { requiresAuth: true }
    },
    {
        path: '/revenue',
        component: RevenueView,
        meta: { requiresAuth: true }
    },
    {
        path: '/rates',
        component: FeesAndRatesView,
        meta: { requiresAuth: true }
    },
    // {
    //     path: '/notes',
    //     component: NotesView,
    //     meta: { requiresAuth: true }
    // },
    {
        path: '/settings',
        component: PropertySettingsView,
        meta: { requiresAuth: true }
    }
    ,
    {
        path: '/about',
        component: AboutView,
        meta: { requiresGuest: true }
    }
    ,
    {
        path: '/contact',
        component: ContactView,
        meta: { requiresGuest: true }
    }
    ,
    {
        path: '/blog',
        component: ComingSoon,
        meta: { requiresGuest: true }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, _from, next) => {
    const auth = useAuthStore();

    if (to.meta.requiresAuth && !auth.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresGuest && auth.isAuthenticated) {
        next('/dashboard');
    } else {
        next();
    }
});

export default router;
