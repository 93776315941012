<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
        <div class="bg-white rounded-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
            <div class="p-6 space-y-4">
                <h2 class="text-xl font-semibold">Bulk Create Listings</h2>

                <!-- Error display -->
                <div v-if="error" class="bg-red-100 text-red-700 border border-red-200 p-2">
                    {{ error }}
                </div>

                <form @submit.prevent="submitBulk" class="space-y-3">
                    <!-- Type -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Type</label>
                        <select v-model="type"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200">
                            <option disabled value="">Select type</option>
                            <option value="single_room">Single Room</option>
                            <option value="family_room">Family Room</option>
                            <option value="queen_room">Queen Room</option>
                            <option value="king_room">King Room</option>
                            <option value="suite">Suite</option>
                            <option value="apartment">Apartment/Unit/Villa</option>
                            <option value="cabin">Cabin</option>
                            <option value="share_room">Share Room/Hostel</option>
                            <option value="house">House</option>
                            <option value="camp_site">Camp Site</option>
                            <option value="caravan">Caravan</option>
                            <option value="bed_breakfast">Bed and Breakfast</option>
                        </select>
                    </div>

                    <!-- Name -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Display Name</label>
                        <input type="text" v-model="name"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                            placeholder="e.g. Family Room" />
                    </div>

                    <!-- Description -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea v-model="description" rows="2"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200" />
                    </div>

                    <!-- Default Rate -->
                    <div>
                        <label class="block text-sm font-medium text-gray-700">Default Rate</label>
                        <input type="number" step="0.01" v-model="defaultRate"
                            class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                            placeholder="e.g. 145.50" />
                    </div>

                    <!-- Count and Start Number in same row -->
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">How Many Listings?</label>
                            <input type="number" v-model="count"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                                placeholder="e.g. 10" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Auto Numbering Start</label>
                            <input type="number" v-model="startNumber"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full focus:ring focus:ring-blue-200"
                                placeholder="e.g. 1" />
                        </div>
                    </div>

                    <!-- Beds, Max Guests grid -->
                    <div class="grid grid-cols-2 gap-3">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Beds</label>
                            <input type="number" v-model="beds"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Max Guests</label>
                            <input type="number" v-model="maximumGuests"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Max Adults</label>
                            <input type="number" v-model="maximumAdults"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Max Children</label>
                            <input type="number" v-model="maximumChildren"
                                class="mt-1 border border-gray-300 rounded p-1.5 w-full" />
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="flex justify-end space-x-3 pt-2">
                        <button type="button" @click="$emit('close')"
                            class="px-4 py-2 border border-gray-300 rounded text-gray-700">
                            Cancel
                        </button>
                        <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { api } from '@/lib/api'

interface Props {
    propertyId: number | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['close', 'created'])

const error = ref('')
const type = ref('')
const name = ref('')
const description = ref('')
const defaultRate = ref<number | null>(null)
const count = ref<number | null>(null)
const startNumber = ref<number>(1)
const beds = ref<number>(2)
const maximumGuests = ref<number>(4)
const maximumAdults = ref<number>(2)
const maximumChildren = ref<number>(2)

async function submitBulk() {
    if (!props.propertyId) {
        error.value = 'No property selected.'
        return
    }
    error.value = ''

    try {
        let identifiers = []
        for (let i = 0; i < count.value; i++) {
            identifiers.push((startNumber.value + i).toString())
        }

        const payload = {
            propertyId: props.propertyId,
            type: type.value,
            name: name.value,
            description: description.value,
            defaultRate: parseInt(defaultRate.value * 100),
            numbers: identifiers,
            beds: beds.value,
            maxGuests: maximumGuests.value,
            maxAdults: maximumAdults.value,
            maxChildren: maximumChildren.value
        }

        await api.post('/listings/bulk', payload)
        emit('created')
    } catch (err: any) {
        console.error('Bulk create error:', err)
        error.value = err?.response?.data?.message || 'Failed to bulk create.'
    }
}
</script>
