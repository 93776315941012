<template>
    <div class="min-h-screen bg-indigo-500">
        <DashboardNav />

        <div class="max-w-7xl mx-auto py-8 px-4">
            <h1 class="text-2xl font-semibold text-gray-100 mb-6">
                Fees &amp; Rates for {{ currentProperty?.name || '...' }}
            </h1>

            <!-- If no property selected -->
            <div v-if="!currentProperty">
                <p class="text-yellow-400">
                    No property selected. Please select or create a property first.
                </p>
            </div>

            <!-- If property selected -->
            <div v-else>

                <!-- Buttons to add new listingFee, seasonalRate, occupancyRate, etc. -->
                <div class="flex space-x-4 mb-4">
                    <button class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                        @click="showListingFeeModal = true">
                        + New Listing Fee
                    </button>
                    <button class="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                        @click="showSeasonalModal = true">
                        + New Seasonal Rate
                    </button>
                    <button class="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700"
                        @click="showOccupancyModal = true">
                        + New Occupancy Rate
                    </button>
                </div>

                <!-- Loading indicator -->
                <div v-if="loading" class="text-gray-100">
                    Loading fees &amp; rates...
                </div>

                <div v-else>
                    <!-- Listing Fees Section -->
                    <h2 class="text-xl font-medium mt-6 text-gray-100 mb-2">Listing Fees</h2>
                    <div v-if="listingFees && listingFees.length" class="grid md:grid-cols-2 gap-4">
                        <div v-for="fee in listingFees" :key="fee.id" class="bg-white p-4 rounded shadow">
                            <p>Type: {{ fee.attributes?.type }}</p>
                            <p>Rate: ${{ fee.attributes?.rate.toFixed(2) }}</p>
                            <div class="mt-3 flex space-x-2">
                                <button @click="editListingFee(fee)"
                                    class="mt-2 px-3 py-1 bg-yellow-400 text-white rounded text-sm">
                                    Edit
                                </button>
                                <button class="mt-2 px-3 py-1 bg-red-500 text-white text-sm rounded"
                                    @click="deleteListingFee(fee)">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                    <p v-else class="text-gray-100 italic">
                        No listing fees found.
                    </p>

                    <!-- Seasonal Rates Section -->
                    <h2 class="text-xl font-medium mt-6 text-gray-100 mb-2">Seasonal Rates</h2>
                    <div v-if="seasonalRates && seasonalRates.length" class="grid md:grid-cols-2 gap-4">
                        <div v-for="rate in seasonalRates" :key="rate.id" class="bg-white p-4 rounded shadow">
                            <p>Type: {{ rate.attributes?.type }}</p>
                            <p>Season: {{ rate.attributes?.season }}</p>
                            <p>Start: {{ rate.attributes?.startDate }} End: {{ rate.attributes?.endDate }}</p>
                            <p>Rate: {{ rate.attributes?.rate }} ({{ rate.attributes?.percentage ? '%' : '$' }})</p>
                            <div class="mt-3 flex space-x-2">
                                <button @click="editSeasonalRate(rate)"
                                    class="mt-2 px-3 py-1 bg-yellow-400 text-white rounded text-sm">
                                    Edit
                                </button>
                                <button class="mt-2 px-3 py-1 bg-red-500 text-white text-sm rounded"
                                    @click="deleteSeasonalRate(rate)">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                    <p v-else class="text-gray-100 italic">
                        No seasonal rates found.
                    </p>

                    <!-- Occupancy Rates Section -->
                    <h2 class="text-xl font-medium mt-6 mb-2 text-gray-100">Occupancy Rates</h2>
                    <div v-if="occupancyRates && occupancyRates.length" class="grid md:grid-cols-2 gap-4">
                        <div v-for="occ in occupancyRates" :key="occ.id" class="bg-white p-4 rounded shadow">
                            <p>Type: {{ occ.attributes?.type }}</p>
                            <p>Trigger: {{ occ.attributes?.trigger }} ({{ occ.attributes?.triggerPercentage ? '%' : '$'
                                }})</p>
                            <p>Rate: {{ occ.attributes?.rate }} ({{ occ.attributes?.percentage ? '%' : '$' }})</p>
                            <div class="mt-3 flex space-x-2">
                                <button @click="editOccupancyRate(occ)"
                                    class="mt-2 px-3 py-1 bg-yellow-400 text-white rounded text-sm">
                                    Edit
                                </button>
                                <button class="mt-2 px-3 py-1 bg-red-500 text-white text-sm rounded"
                                    @click="deleteOccupancyRate(occ)">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                    <p v-else class="text-gray-100 italic">
                        No occupancy rates found.
                    </p>
                </div>
            </div>
        </div>

        <!-- ListingFeeModal -->
        <ListingFeeModal v-if="showListingFeeModal" :propertyId="currentProperty?.id"
            @close="showListingFeeModal = false" @created="refreshFeesRates" />
        <!-- SeasonalRateModal -->
        <SeasonalRateModal v-if="showSeasonalModal" :propertyId="currentProperty?.id" @close="showSeasonalModal = false"
            @created="refreshFeesRates" />
        <!-- OccupancyRateModal -->
        <OccupancyRateModal v-if="showOccupancyModal" :propertyId="currentProperty?.id"
            @close="showOccupancyModal = false" @created="refreshFeesRates" />
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import DashboardNav from '@/components/DashboardNav.vue'
import { useAuthStore } from '@/stores/auth'
import { api } from '@/lib/api'

// Example modals
import ListingFeeModal from '@/components/ListingFeeModal.vue'
import SeasonalRateModal from '@/components/SeasonalRateModal.vue'
import OccupancyRateModal from '@/components/OccupancyRateModal.vue'

const authStore = useAuthStore()
const currentProperty = computed(() => authStore.propertyInformation)

const loading = ref(false)
const listingFees = ref<any[]>([])
const seasonalRates = ref<any[]>([])
const occupancyRates = ref<any[]>([])

const showListingFeeModal = ref(false)
const showSeasonalModal = ref(false)
const showOccupancyModal = ref(false)

async function loadFeesRates() {
    if (!currentProperty.value?.id) return
    loading.value = true
    try {
        // Either do GET /properties/{id} or a specialized endpoint
        const response = await api.get(`/properties/${currentProperty.value.id}`)
        listingFees.value = response.data.data.relationships?.listingFees || []
        seasonalRates.value = response.data.data.relationships?.seasonalRates || []
        occupancyRates.value = response.data.data.relationships?.occupancyRates || []
    } catch (err) {
        console.error('Error fetching fees & rates:', err)
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    loadFeesRates()
})

function refreshFeesRates() {
    showListingFeeModal.value = false
    showSeasonalModal.value = false
    showOccupancyModal.value = false
    loadFeesRates()
}

// Example delete
async function deleteListingFee(fee: any) {
    if (!confirm('Really delete this listing fee?')) return
    try {
        await api.delete(`/fees/listing/${fee.id}`)
        listingFees.value = listingFees.value.filter(f => f.id !== fee.id)
    } catch (err) {
        console.error('Failed to delete listing fee', err)
    }
}

async function deleteSeasonalRate(rate: any) {
    if (!confirm('Really delete this seasonal rate?')) return
    try {
        await api.delete(`/rates/seasonal/${rate.id}`)
        seasonalRates.value = seasonalRates.value.filter(r => r.id !== rate.id)
    } catch (err) {
        console.error('Failed to delete seasonal rate', err)
    }
}

async function deleteOccupancyRate(occ: any) {
    if (!confirm('Really delete this occupancy rate?')) return
    try {
        await api.delete(`/rates/occupancy/${occ.id}`)
        occupancyRates.value = occupancyRates.value.filter(o => o.id !== occ.id)
    } catch (err) {
        console.error('Failed to delete occupancy rate', err)
    }
}

async function editOccupancyRate(occ: any) {
    console.log('TODO: edit occupancy rate', occ)
}

async function editSeasonalRate(rate: any) {
    console.log('TODO: edit seasonal rate', rate)
}

async function editListingFee(fee: any) {
    console.log('TODO: edit listing fee', fee)
}
</script>

<style scoped>
</style>
