<!-- src/views/DashboardView.vue -->
<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { api } from '@/lib/api'
import { useAuthStore } from '@/stores/auth'
import { DateTime } from 'luxon'
import RevenueChart from '@/components/RevenueChart.vue'
import DashboardNav from '@/components/DashboardNav.vue'
const auth = useAuthStore()

// Today's key metrics
const todayMetrics = ref({
    arrivals: 0,
    departures: 0,
    occupancyRate: 0,
    totalRooms: 0,
    occupiedRooms: 0
})

// Recent activity
const recentBookings = ref([])
const systemNotifications = ref([])

// Add to your script setup
const showCharts = ref(localStorage.getItem('dashboardShowCharts') !== 'false')

// Watch for changes and save preference
watch(showCharts, (newValue) => {
    localStorage.setItem('dashboardShowCharts', newValue.toString())
})

// Financial metrics (placeholder data)
const revenueData = [
    { month: 'Jan', revenue: 12400, expenses: 8200, profit: 4200 },
    { month: 'Feb', revenue: 15800, expenses: 9100, profit: 6700 },
    { month: 'Mar', revenue: 14100, expenses: 8800, profit: 5300 }
]

// Comparison metrics
const comparisons = ref({
    bookings: {
        current: 45,
        previous: 38,
        percentageChange: 18.4
    },
    revenue: {
        current: 52400,
        previous: 48900,
        percentageChange: 7.2
    }
})

const revenueChartData = computed(() => ({
    labels: revenueData.map(item => item.month),
    datasets: [
        {
            label: 'Revenue',
            data: revenueData.map(item => item.revenue),
            borderColor: '#4F46E5', // Indigo
            backgroundColor: 'rgba(79, 70, 229, 0.1)',
            tension: 0.4
        },
        {
            label: 'Expenses',
            data: revenueData.map(item => item.expenses),
            borderColor: '#EF4444', // Red
            backgroundColor: 'rgba(239, 68, 68, 0.1)',
            tension: 0.4
        },
        {
            label: 'Profit',
            data: revenueData.map(item => item.profit),
            borderColor: '#10B981', // Green
            backgroundColor: 'rgba(16, 185, 129, 0.1)',
            tension: 0.4
        }
    ]
}))

// Load today's arrivals and departures
async function loadTodayBookings() {
    try {
        const today = DateTime.now().toFormat('yyyy-LL-dd')
        const response = await api.get(`/properties/${auth.selectedProperty}/bookings`, {
            params: {
                start_date: today,
                end_date: today
            }
        })

        // Process bookings to separate arrivals and departures
        const bookings = response.data.data || []
        todayMetrics.value.arrivals = bookings.filter(b => b.attributes.checkIn === today).length
        todayMetrics.value.departures = bookings.filter(b => b.attributes.checkOut === today).length

        console.info(`Today is ${today}, ${todayMetrics.value.arrivals} arrivals, ${todayMetrics.value.departures} departures`)

        // Update recent bookings list
        recentBookings.value = bookings.map(processBooking)
    } catch (error) {
        console.error('Error loading today\'s bookings:', error)
    }
}

// Format currency for display
function formatCurrency(amount: number): string {
    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD'
    }).format(amount)
}

// Process booking data for display
function processBooking(booking: any) {
    return {
        id: booking.id,
        guestName: `${booking.relationships.guest?.attributes.lastName.toUpperCase()}, ${booking.relationships.guest?.attributes.firstName}`,
        checkIn: DateTime.fromISO(booking.attributes.checkIn).toFormat('dd LLL yyyy'),
        checkOut: DateTime.fromISO(booking.attributes.checkOut).toFormat('dd LLL yyyy'),
        totalAmount: formatCurrency(booking.attributes.totalFee / 100),
        status: booking.attributes.status
    }
}

const isLoadingMore = ref(false)

// When charts are hidden, you might want to load more bookings
watch(showCharts, async (newValue) => {
    if (!newValue) {
        isLoadingMore.value = true
        try {
            // Load additional booking data
            // await loadMoreBookings()
        } finally {
            isLoadingMore.value = false
        }
    }
})

onMounted(() => {
    loadTodayBookings()
})
</script>

<template>
    <div class="min-h-screen bg-gray-100">
        <!-- Navigation -->
        <DashboardNav />

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <!-- Header with Toggle -->
            <div class="mb-8 flex justify-between items-center">
                <div>
                    <h1 class="text-2xl font-semibold text-gray-900">{{ auth.propertyInformation?.name }}</h1>
                    <p class="text-gray-500">Dashboard Overview</p>
                </div>
                <!-- Toggle Button -->
                <button @click="showCharts = !showCharts"
                    class="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium transition-colors" :class="[
                        showCharts
                            ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    ]">
                    <span class="mr-2">{{ showCharts ? 'Hide' : 'Show' }} Charts</span>
                    <svg class="w-4 h-4 transition-transform" :class="{ 'rotate-180': !showCharts }" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </div>

            <!-- Today's Metrics Grid -->
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <!-- Your existing metrics cards remain unchanged -->
                <div class="bg-white rounded-lg shadow p-6">
                    <h3 class="text-lg font-medium text-gray-900 mb-2">Today's Arrivals</h3>
                    <div class="flex items-baseline">
                        <p class="text-3xl font-semibold text-indigo-600">{{ todayMetrics.arrivals }}</p>
                        <p class="ml-2 text-sm text-gray-500">guests</p>
                    </div>
                </div>

                <div class="bg-white rounded-lg shadow p-6">
                    <h3 class="text-lg font-medium text-gray-900 mb-2">Today's Departures</h3>
                    <div class="flex items-baseline">
                        <p class="text-3xl font-semibold text-indigo-600">{{ todayMetrics.departures }}</p>
                        <p class="ml-2 text-sm text-gray-500">guests</p>
                    </div>
                </div>

                <div class="bg-white rounded-lg shadow p-6">
                    <h3 class="text-lg font-medium text-gray-900 mb-2">Current Occupancy</h3>
                    <div class="flex items-baseline">
                        <p class="text-3xl font-semibold text-indigo-600">
                            {{ todayMetrics.occupancyRate }}%
                        </p>
                        <p class="ml-2 text-sm text-gray-500">
                            ({{ todayMetrics.occupiedRooms }}/{{ todayMetrics.totalRooms }} rooms)
                        </p>
                    </div>
                </div>
            </div>

            <!-- Charts Row with Transition -->
            <Transition enter-active-class="transition-all duration-300 ease-out"
                enter-from-class="opacity-0 transform -translate-y-4"
                enter-to-class="opacity-100 transform translate-y-0"
                leave-active-class="transition-all duration-300 ease-in"
                leave-from-class="opacity-100 transform translate-y-0"
                leave-to-class="opacity-0 transform -translate-y-4">
                <div v-if="showCharts" class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    <div class="bg-white rounded-lg shadow p-6">
                        <h3 class="text-lg font-medium text-gray-900 mb-4">Revenue Overview</h3>
                        <div class="h-64">
                            <RevenueChart :chart-data="revenueChartData" />
                        </div>
                    </div>

                    <div class="bg-white rounded-lg shadow p-6">
                        <h3 class="text-lg font-medium text-gray-900 mb-4">Performance vs Last Month</h3>
                        <div class="space-y-4">
                            <div class="flex justify-between items-center">
                                <span class="text-gray-500">Bookings</span>
                                <div class="flex items-center">
                                    <span class="text-2xl font-semibold">{{ comparisons.bookings.current }}</span>
                                    <span class="ml-2 text-sm" :class="[
                                        comparisons.bookings.percentageChange > 0 ? 'text-green-500' : 'text-red-500'
                                    ]">
                                        {{ comparisons.bookings.percentageChange > 0 ? '↑' : '↓' }}
                                        {{ Math.abs(comparisons.bookings.percentageChange) }}%
                                    </span>
                                </div>
                            </div>
                            <div class="flex justify-between items-center">
                                <span class="text-gray-500">Revenue</span>
                                <div class="flex items-center">
                                    <span class="text-2xl font-semibold">{{ formatCurrency(comparisons.revenue.current)
                                        }}</span>
                                    <span class="ml-2 text-sm" :class="[
                                        comparisons.revenue.percentageChange > 0 ? 'text-green-500' : 'text-red-500'
                                    ]">
                                        {{ comparisons.revenue.percentageChange > 0 ? '↑' : '↓' }}
                                        {{ Math.abs(comparisons.revenue.percentageChange) }}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>

            <!-- Recent Activity with Dynamic Classes -->
            <div class="grid gap-6 transition-all duration-300" :class="[
                showCharts ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1 md:grid-cols-3'
            ]">
                <!-- Recent Bookings - Dynamically sized -->
                <div class="bg-white rounded-lg shadow transition-all duration-300"
                    :class="{ 'md:col-span-2': !showCharts }">
                    <div class="px-6 py-4 border-b border-gray-200">
                        <h3 class="text-lg font-medium text-gray-900">Recent Bookings</h3>
                    </div>
                    <div class="divide-y divide-gray-200">
                        <div v-for="booking in recentBookings" :key="booking.id" class="p-6">
                            <div class="flex justify-between">
                                <div>
                                    <p class="font-medium text-gray-900">{{ booking.guestName }}</p>
                                    <p class="text-sm text-gray-500">
                                        {{ booking.checkIn }} - {{ booking.checkOut }}
                                    </p>
                                </div>
                                <div class="text-right">
                                    <p class="font-medium text-gray-900">{{ booking.totalAmount }}</p>
                                    <p class="text-sm" :class="{
                                        'text-green-600': booking.status === 'confirmed',
                                        'text-yellow-600': booking.status === 'pending',
                                        'text-red-600': booking.status === 'cancelled',
                                        'text-red-700': booking.status === 'locked'
                                    }">
                                        {{ booking.status === 'locked' ? 'Unconfiirmed' : booking.status }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- System Notifications -->
                <div class="bg-white rounded-lg shadow">
                    <div class="px-6 py-4 border-b border-gray-200">
                        <h3 class="text-lg font-medium text-gray-900">System Notifications</h3>
                    </div>
                    <div class="p-6">
                        <div v-if="systemNotifications.length === 0" class="text-gray-500 text-center py-4">
                            No new notifications
                        </div>
                        <div v-else v-for="notification in systemNotifications" :key="notification.id"
                            class="mb-4 p-4 rounded-lg" :class="notification.type">
                            <p class="font-medium">{{ notification.title }}</p>
                            <p class="text-sm">{{ notification.message }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
